/**
 * Storage manager for local storage and session storage
 */
class BaseStorage {

    keys: StringDictType = {}

    storage?: Storage;

    set(key: string, value: any) {
        this.storage!.setItem(key, JSON.stringify(value));
    }

    get(key: string) {
        const value = this.storage!.getItem(key);
        if (value) {
            try {
                return JSON.parse(value!);
            } catch {
                console.log(`error getting local storage | key: ${key}, value: ${value!}`)
                return null
            }
        }
    }

    delete(key: string) {
        this.storage!.removeItem(key);
    }

    // Returns all keys on local storage
    getAllKeys() {
        let keys = [];
        for (let i = 0; i < this.storage!.length; i++) {
            let key = this.storage!.key(i);
            keys.push(key);
        }
        return keys
    }

}

// Persisent storage
export default class AppStorage extends BaseStorage {

    keys: StringDictType = {
        'token': 'token',
        'last_visit': 'last_visit',
        'subscription_pricing_key': 'subscription_pricing_key',
        'subscription_is_annual': 'subscription_is_annual',
        'subscription_price': 'subscription_price',
        'first_month_price': 'first_month_price',
        'lead_tracked': 'lead_tracked',
        'purchase_tracked': 'purchase_tracked',
    }

    storage = localStorage;

}

// Storage for this session, i.e. browser tab
export class SessionStorage extends BaseStorage {

    keys: StringDictType = {
        'email': 'email',
        'login_redirect_post_slug': 'login_redirect_post_slug',
    }

    storage = sessionStorage;

}
