import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import URLS from 'urls';
import { ellipsify, setPageMetaData } from 'utils/uihelpers';


/**
 * Custom hook to redirect to the login page if the user is not authenticated
 */
export function useLoginRedirect(userPromise: UserType) {

    const navigate = useNavigate();

    useEffect(() => {
        if(userPromise) {
            userPromise.then((user: DictType) => {
                !user.id && navigate(URLS('login'));
            });
        }
    }, [navigate, userPromise]);
}

/**
 * Custom hook to redirect to the create user page if the user is not authenticated
 */
export function useCreateUserRedirect(userPromise: UserType) {

    const navigate = useNavigate();

    useEffect(() => {
        if(userPromise) {
            userPromise.then((user: DictType) => {
                !user.id && navigate(URLS('createUser'));
            });
        }
    }, [navigate, userPromise]);
}

/**
 * Custom hook to redirect NoPage if the user is not a publisher
 */
export function usePublisherRedirect(userPromise: UserType) {

    const navigate = useNavigate();

    useEffect(() => {
        if(userPromise) {
            userPromise.then((user: DictType) => {
                !user.is_publisher && navigate(URLS('404'));
            });
        }
    }, [navigate, userPromise]);
}


export function usePostMetaData(postPromise: PostType) {
    useEffect(() => {
        if (postPromise) {
            (postPromise as DictType).then((post: PostType) => {
                setPageMetaData({
                    title: post.title!,
                    description: post.subtitle ? post.subtitle : ellipsify(post.content, 150),
                    image: post.image ? post.image : undefined,
                })
            });
        }
    }, [postPromise]);
}

