import { Link, Await, useLoaderData } from "react-router-dom";
import { Suspense } from 'react';
import { showPaywall } from 'utils/permissions';
import logo from 'img/logo.png';
import URLS, { getSubscribeUrl } from 'urls';
import Settings from 'utils/settings';
import HamburgerMenu from 'components/navbar/HamburgerMenu';


/**
 * Navigation bar for the site
 */
export default function NavBar(props: { hideMenu?: boolean }) {
    const { userPromise } = useLoaderData() as {userPromise: UserType};

    // Hide login and subscribe buttons on the pricing and subscribe screens
    const isSubscribeUrl = (): boolean => (
        window.location.pathname === URLS('createUser')
        || window.location.pathname === URLS('subscribe')
        || window.location.pathname === URLS('subscribeSuccess')
        || window.location.pathname === URLS('pricing')
    );

    return (
        <nav className="navbar">
            <Logo />

            {!props.hideMenu && 
            <>

            {/* Subscribe button or hamburger menu */}
            <Suspense>
                <Await resolve={userPromise}>
                    {(user) =>
                        // Hide menu options on pricing/subscription pages
                        !isSubscribeUrl() && (
                            // Logged in
                            user.id ? (
                                // Logged in, non paying subscriber
                                showPaywall(user) ?
                                <>
                                    <HamburgerMenu />
                                    <Link to={getSubscribeUrl(user)} className="subscribe-btn">Subscribe</Link>
                                </>
                                // Logged in, currently paying
                                : <HamburgerMenu />
                            )
                            // Logged out
                            :
                            <>
                                <Link to={getSubscribeUrl(user)} className="subscribe-btn">Subscribe</Link>
                                <Link to={URLS('login')} className="login">Login</Link>
                            </>                             
                        )
                    }
                </Await>
            </Suspense>
            </>}

        </nav>
    )
}


export function NavBarNoData() {
    return (
        <>
            <nav className="navbar">
                <Logo />
                <Link to={URLS('subscribe')} className="subscribe-btn">Subscribe</Link>
                <Link to={URLS('login')} className="login">Login</Link>
            </nav>
        </>
    )
}


function Logo() {
    return (
        <Link to={URLS('home')} className='logo'>            
            <img src={logo} alt=""/>
            <span>{Settings.COMPANY_NAME}</span>
        </Link>        
    )
}