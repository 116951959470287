import { defer } from 'react-router-dom';
import API from 'utils/api';


// Accounts page loader. Get user stats and subscription information.
export function accountLoader() {
    const api = new API();
    const statsPromise = api.getUserStats();
    const subscriptionPromise = api.getSubscription();
    const portalPromise = api.getSubscriptionPortal('account'); 
    return defer({ statsPromise, subscriptionPromise, portalPromise });
}

// Settings page loader. Get notifications preferences.
export function settingsLoader() {
    const api = new API();
    const notificationsPromise = api.getNotificationSettings();
    return defer({ notificationsPromise });
}

// Subscription settings page loader
export function subscriptionSettingsLoader() {
    const api = new API();
    const subscriptionPromise = api.getSubscription();
    const portalPromise = api.getSubscriptionPortal('settings'); 
    return defer({ subscriptionPromise, portalPromise });
}
