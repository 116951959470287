import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signupLanguage } from 'language/signup_language';
import { useNavigate } from 'react-router-dom';
import AppStorage from 'utils/appstorage';


/**
 * Pricing selector for monthly only pricing scheme with a coupon, e.g. monthly_coupon_1-00_9-99
 */
export default function PricingCouponMonthly({ pricing }: { pricing?: DictType }) {

    const navigate = useNavigate();

    // Set pricing on local storage for subscription screen
    const appStorage = new AppStorage();
    appStorage.set(appStorage.keys.subscription_is_annual, false);
    appStorage.set(appStorage.keys.subscription_price, pricing?.scheme.monthly_price);
    appStorage.set(appStorage.keys.first_month_price, pricing?.scheme.first_month_price);

    return (
        <>
        <div className="option selected coupon">
            {pricing ?
            <>
                <h1>${pricing?.scheme?.first_month_price / 100} <span>for a month</span></h1>
                <h6>Then ${pricing?.scheme?.monthly_price / 100} a month</h6>
            </>
            :

            <>
                <div className="placeholder-content-shimmer">
                    <div className="shimmerBG darker pricing-h1"></div>
                    <div className="shimmerBG darker pricing-h6"></div>
                </div>
            </>}

            <div className="value-props">
                {signupLanguage.pricingValueProps.map((item: string, index: number) => (
                    <div className="pure-g item" key={index}>
                        <div className="pure-u-1-24"><FontAwesomeIcon icon={faCheckCircle} className="icon" /></div>
                        <div className="pure-u-23-24 text">{item}</div>
                    </div>
                ))}
            </div>

        </div>   

        {/* Note above button. Placeholder to $1, and then replace */}
        {pricing ?
        <span className="button-note">Try <b>a month for just ${pricing?.scheme?.first_month_price / 100}</b>. Cancel in 30 days and you won’t be charged again.</span>
        :
        <span className="button-note">Try <b>a month for just $1</b>. Cancel in 30 days and you won’t be charged again.</span>
        }
        
        </>
    )
}