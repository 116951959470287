import { Await, Link, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCircleCheck, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import URLS from 'urls';
import API from 'utils/api';
import { PostCache } from 'storage/postcache';
import { Suspense, useEffect } from 'react';
import AppStorage, { SessionStorage } from 'utils/appstorage';
import { useCreateUserRedirect } from 'utils/hooks';
import { trackPurchaseExternal } from 'utils/advertising';
import 'css/subscription.scss';


export default function SubscribeSuccess() {

    const [searchParams] = useSearchParams();
    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const navigate = useNavigate();
    const appStorage = new AppStorage();
    const sessionStorage = new SessionStorage();

    // Redirect to create user if user is not logged in
    useCreateUserRedirect(userPromise);

    // Confirm that the payment intent succeed. If it failed, redirect back to create user.
    useEffect(() => {
        const paymentIntentId = searchParams.get('payment_intent');
        if (paymentIntentId) {
            const paymentPromise = new API().confirmPayment(paymentIntentId);
            (paymentPromise as any).then((response: StringDictType) => {
                if(response.status === 'requires_payment_method') {
                    navigate(URLS('subscribe') + '?payment=failed');
                }
            });                
        }
    }, [userPromise, navigate, searchParams]);

    // Track purchase to ad channels, e.g. FB
    trackPurchaseExternal()

    // If came from a post paywall, redirect to the post, otherwise redirect home
    let redirectUrl = URLS('home');
    const postSlug = sessionStorage.get(sessionStorage.keys.login_redirect_post_slug);
    if(postSlug) {
        redirectUrl = URLS('post', 'slug', postSlug);
    }
    
    // Delete all cached posts on login so that you're not viewing cached previews
    new PostCache().deleteAll({ staleOnly: false })

    // Delete last_visit local timestamp to force an identify call
    appStorage.delete(appStorage.keys.last_visit);

    // Build the context for the body of the confirm screen
    function getScenario(isEmailVerified: boolean) {
        const isPriorPayment = searchParams.get('existing_payment') === 'true';

        let scenario = [];

        // Part 1: payment
        if (isPriorPayment) {
            scenario.push(["You already have an active subscription so you were not charged.", faCircleCheck]);
        } else {
            scenario.push(["We received your payment and you’re now automatically logged in.", faCircleCheck]);
        }
        // Part 2: email
        if (isEmailVerified) {
            scenario.push(["Look out for your first newsletter this week.", faEnvelope]);
        } else {
            scenario.push(["We just sent you an email. Please verify your email address. This is the email you’ll use to access your account.", faEnvelope]);
        }

        return scenario
    }

    return (
        <div className="desktop-utilities-content pricing subscribe-success">
            <div className="header">
                <h2 className="header">Welcome!</h2>
                <p>We’re excited to welcome you to Foundation. A couple housekeeping items below, and then let’s dive in.</p>
            </div>
            <div className="body">
                <h4>What's next</h4>
                <Suspense>
                    <Await resolve={userPromise}>
                        {(user: UserType) => <WhatsNext items={getScenario(user.is_email_verified)} />}
                    </Await>
                </Suspense>
                <Link to={redirectUrl} className="btn-primary">Continue</Link>
            </div>
        </div>
    )
}

/**
 * Component to render each line of the bottom with an icon and a message
 */
function WhatsNext({ items }: { items: (string | IconDefinition)[][] }) {
    const output = items.map((item, index) =>
        <div className="pure-g" key={index}>
            <div className="pure-u-4-24 pure-u-md-3-24">
                <span className="icon"><FontAwesomeIcon icon={item[1] as IconDefinition} /></span>
            </div>
            <div className="pure-u-20-24 pure-u-md-21-24">
                <span className="text">{item[0] as string}</span>
            </div>
        </div>        
    )
    return <>{output}</>
}
