import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showBanner } from "components/Banner";
import { PostPostType } from "pages/post/Post";
import { useRef, useState } from "react";
import { Form, Link, useNavigate, useSearchParams } from "react-router-dom";
import URLS, { getSubscribeUrl } from 'urls';
import { trackLeadExternal } from "utils/advertising";
import API, { hasAuthToken } from "utils/api";
import AppStorage, { SessionStorage } from "utils/appstorage";
import { showPaywall, showPostPaywall } from 'utils/permissions';
import { helpCenterUrl } from "utils/settings";


/**
 * Render the paywall for the home page
 */
export function Paywall({
    user,
    post,
}: {
    user: UserType,
    post?: PostType,
}) {
    // Create user or pay for subscription
    const url: string = getSubscribeUrl(user);
    const [searchParams] = useSearchParams();

    function hidePaywall(user: UserType): boolean {
        let hidePostPaywall = false;
        if (post) {
            hidePostPaywall = !showPostPaywall(user, post);
        }
        return (
            // Paywall not eligible
            !showPaywall(user)
            // Hide if just paid
            || searchParams.get('warning') === 'otp'
            // Hide on post page if post is public
            || hidePostPaywall
        )
    }
    
    return (
        // Hide paywall
        hidePaywall(user) ? null :
        // Show paywall
        <>
            <Link to={url} className="paywall-drawer paywall-base">
                <div className="pure-g">
                <div className="pure-u-1-1 pure-u-lg-4-5">
                    <h4>Join now</h4>
                    <p>Subscribe for original viewpoints from CEOs, founders and insiders.</p>
                </div>
                <div className="pure-u-1-1 pure-u-lg-1-5 trial-button">
                    <button>Subscribe</button>
                </div>
                </div>
            </Link>
        </>
    )
}


/**
 * Render the paywall for a post that creates a user
 */
export function PostPaywall({ user, post }: { user: UserType, post: PostType }) {
    
    const emailRef: React.RefObject<HTMLInputElement> = useRef(null);
    const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);
    
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);

    // Create a user
    async function handleSubscribe() {
        const email = emailRef.current!.value;
        const button = buttonRef.current!;
        const isEmailValid = typeof email === 'string' && email.includes('@');
        if (!isEmailValid) {
            emailRef.current!.classList.add('input-error');
            showBanner('Please enter a valid email.');
            return
        }

        // Disable button to avoid double user creation on double tap
        button.disabled = true;
        setInProgress(true);

        // Blocking user creation call
        // Will also auto log in the user if account created
        const user = await new API().getOrCreateUser({
            email: email!.toString(),
        });
        const userCreated = Boolean(user?.token);

        // Error creating the user
        if (user.message) {
            showBanner(user.message);
            button.disabled = false;
            setInProgress(false);
            return
        }

        // If user not created and not logged in (no token), send login email and go to loginredirect page
        if (!userCreated && !hasAuthToken()) {
            new API().sendOTPToken(email!.toString());
            // Note: error should never happen bc we just validated the email with
            // getOrCreateUser, so proceed optimistically
            navigate(URLS('loginConfirmExistingUser'));
            return
        }

        // Track create user event to external ad platform
        trackLeadExternal();

        // Delete last_visit local timestamp to force an identify call
        const appStorage = new AppStorage();
        appStorage.delete(appStorage.keys.last_visit);

        // Store the url the user came from so we can redirect them after signing up
        const sessionStorage = new SessionStorage();
        sessionStorage.set(sessionStorage.keys.login_redirect_post_slug, post.slug);
        
        // Go to subscribe screen (don't use navigate bc it will rely on the user
        // in the layout and we need to force reload user)
        window.location.href = URLS('createUser');
    }

    // ** Display criteria **

    // No applicable paywall
    if (!showPaywall(user)) {
        return <></>
    }
    
    // Show a regular paywall without signup if post is public, or user is logged in, or its not a full post
    if (post.is_public || user.id || post.post_type !== PostPostType.full) {
        return <Paywall user={user} />
    }

    return (
        <div className="paywall-drawer paywall-post">
            <Form>
                <h3>Read the full story</h3>
                <p>Subscribe to read more from CEOs, founders and insiders.</p>
                <label>Email address</label>
                <input type="email" placeholder="Enter email" ref={emailRef} />
                <button className="btn-primary" onClick={handleSubscribe} ref={buttonRef}>
                    Continue
                    {inProgress &&
                    <FontAwesomeIcon icon={faCircleNotch} className="spinner fa-spin fast-spin" />}
                </button>
                <span className="disclosure">I agree to the <Link to={helpCenterUrl('terms')} target="_blank">Terms of Use</Link> and <Link to={helpCenterUrl('privacy')} target="_blank">Privacy Policy</Link>.</span>
            </Form>
        </div>
    )
}