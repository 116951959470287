import AppStorage from 'utils/appstorage';
import Settings from 'utils/settings';

/**
 * Save the utm params to local storage whenever available so
 * they can be passed in create user
 */
export function storeUtmParams(searchParams: DictType) {
    const utm_channel = searchParams.get('utm_channel');
    const utm_campaign = searchParams.get('utm_campaign');
    const utm_ad_set = searchParams.get('utm_ad_set');
    const utm_ad = searchParams.get('utm_ad');

    // Store utm code only if it has a value
    const appStorage = new AppStorage();
    utm_channel && appStorage.set('utm_channel', utm_channel);
    utm_campaign && appStorage.set('utm_campaign', utm_campaign);
    utm_ad_set && appStorage.set('utm_ad_set', utm_ad_set);
    utm_ad && appStorage.set('utm_ad', utm_ad);
}

/**
 * Get utm params from local storage as a dictionary
 */
export function getUtmParams() {
    const appStorage = new AppStorage();
    return {
        utm_channel: appStorage.get('utm_channel'),
        utm_campaign: appStorage.get('utm_campaign'),
        utm_ad_set: appStorage.get('utm_ad_set'),
        utm_ad: appStorage.get('utm_ad')
    }
}

/**
 * Add Google Analytics right below the head tag
 */
export function addGoogleAnalytics() {
    // GA script
    let gaScript = document.createElement('script');
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${Settings.GOOGLE_ANALYTICS_GA4_TOKEN}`;
    gaScript.async = true;
    // GA config script
    var configScript = document.createElement('script');
    configScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${Settings.GOOGLE_ANALYTICS_GA4_TOKEN}');
    `;
    // Place right after head tag
    document.head.insertAdjacentElement('afterend', configScript);
    document.head.insertAdjacentElement('afterend', gaScript);
}

/**
 * Add Linkedin conversion tracking script at the end of the body
 * (as per linkedin's instructions)
 */
export function addLinkedinInsightTag() {
    
    // Script 1
    var script_1 = document.createElement('script');
    script_1.innerHTML = `
    _linkedin_partner_id = "${Settings.LINKEDIN_PARTNER_ID}";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;

    // Script 2
    var script_2 = document.createElement('script');
    script_2.innerHTML = `
    (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})(window.lintrk);
    `;
 
    // Place right before the body tag is closed
    const body = document.querySelector('body')!;
    body.appendChild(script_1);
    body.appendChild(script_2);
    
}

/**
 * Add Facebook event tracking script
 */
export function addFacebookPixel() {

    const fbID = '963775141387124';
    var script = document.createElement('script');
    script.innerHTML =  `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fbID}');
        fbq('track', 'PageView');
    `;
    // Place right after head tag
    document.head.insertAdjacentElement('afterend', script);

}

/**
 * Add Twitter event tracking script
 */
export function addTwitterPixel() {
    var script = document.createElement('script');
    script.innerHTML =  `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','${Settings.TWITTER_CONVERSION_ID}');
    `;
    // Place right after head tag
    document.head.insertAdjacentElement('afterend', script);    
}

/**
 * Track creating a user (aka email lead) to external ad platform
 */
export function trackLeadExternal() {
    // Only track in prod
    if (!Settings.isProd()) {
        return
    }

    // Only track lead once
    const appStorage = new AppStorage();
    if (appStorage.get(appStorage.keys.lead_tracked)) {
        return
    }

    // Linkedin
    const linkedinLeadId = 13622436;
    try {
        // @ts-ignore
        window.lintrk('track', { conversion_id: linkedinLeadId });
        appStorage.set(appStorage.keys.lead_tracked, true);
    } catch (error) {
        console.error('Error tracking', error);
    }
    // Facebook
    try {
        // @ts-ignore
        fbq('track', 'CompleteRegistration');
        appStorage.set(appStorage.keys.lead_tracked, true);
    } catch (error) {
        console.error('Error tracking', error);
    }
    // Twitter
    const twitterLeadId = 'tw-ohbk9-ohbke';
    try {
        // @ts-ignore
        twq('event', twitterLeadId, {});
        appStorage.set(appStorage.keys.lead_tracked, true);
    } catch (error) {
        console.error('Error tracking', error);
    }    
}

/**
 * Track purchase to external ad platform
 */
export function trackPurchaseExternal() {
    if (!Settings.isProd()) {
        return
    }

    // Only track purchase once
    const appStorage = new AppStorage();
    if (appStorage.get(appStorage.keys.purchase_tracked)) {
        return
    }

    // Facebook
    try {
        // @ts-ignore
        fbq('track', 'Purchase');
        appStorage.set(appStorage.keys.purchase_tracked, true);
    } catch (error) {
        console.error('Error tracking', error);
    }
    // Twitter
    const twitterPurchaseId = 'tw-ohbk9-ohbkb';
    try {
        // @ts-ignore
        twq('event', twitterPurchaseId, {});
        appStorage.set(appStorage.keys.purchase_tracked, true);
    } catch (error) {
        console.error('Error tracking', error);
    }      

}