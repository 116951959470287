import PostLink from 'components/PostLink';
import { DateFormat } from 'components/common';
import { ellipsify } from 'utils/uihelpers';


/**
 * Render an article preview on an articles list page like bookmarks or drafts
 */
export default function PostPreview({ post }: { post: PostType }) {

    const contentPreviewLength: number = window.innerWidth > 768 ? 180 : 85;
    return (
        <PostLink post={post}>
            <div className="pure-g article">

                {/* Title and content */}
                <div className={`${post.image ? "pure-u-17-24 pure-u-md-18-24" : "pure-u-1-1"}`}>
                    {/* Title */}
                    <h4>{post.title}</h4>
                    <p
                        className="preview"
                        dangerouslySetInnerHTML={{__html: ellipsify(post.content!, contentPreviewLength)}}
                    />
                    {/* Abridged byline */}
                    <div className="byline">
                        {/* Archive badge */}
                        {!post.is_active && <span className="badge archived">Archived</span>}
                        {/* Published timestamp or draft badge */}
                        <span>
                            {post.published_timestamp
                            ? <><DateFormat timestamp={post.published_timestamp} format="MMM d"/> · </>
                            : <><span className="badge">Draft</span> · </>}
                        </span>
                        <span>{post.read_time_minutes} min read</span>
                    </div>
                </div>

                {/* Optional image */}
                {post.image &&
                <div className="pure-u-7-24 pure-u-md-6-24">
                    <img src={post.image} alt="" />
                </div>}

            </div>
        </PostLink>
    )
}