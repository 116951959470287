import AppStorage from 'utils/appstorage';
import API from 'utils/api';
import Settings from 'utils/settings';

/**
 * Manage caching Posts in local storage
 */
export class PostCache {

    // Minutes before we consider the post stale
    minutesStale = Settings.MINUTES_CACHE_STALE;

    keyPrefix = 'post-'
    storage: AppStorage

    constructor() {
        this.storage = new AppStorage();
    }

    // Get the post from the API (or by resolving a promise)
    // Set on local storage
    set({
        postPromise,
        slug,
    } : {
        postPromise?: DictType,
        slug?: string,
    }) {
        // Fresh cached post, return it
        const post = slug ? this.get(slug) : null;
        if (post) {
            return post;
        }
        // Fetch post from backend if promise not passed
        if(!postPromise) {
            postPromise = new API().getPost({slug: slug!});
        }
        postPromise.then((post: PostType) => {
            // Don't cache a post with a version id - i.e. don't cache a
            // past version of a post as the post
            if(!post.version_id) {
                this._set(post);
            }
        });
    }

    // Get the post from local storage
    get(slug: string) {
        const post = this.storage.get(this._key(slug));
        const staleTime = this.minutesStale * 60 * 1000;
        if (post && Date.now() - post.updated_at < staleTime) {
            return post 
        }
    }

    delete(slug: string) {
        this.storage.delete(this._key(slug));
    }

    // Delete all post from the cache
    deleteAll({ staleOnly } : { staleOnly: boolean }) {

        const keys = this.storage.getAllKeys();

        for (let i = 0; i < keys.length; i++) {

            // Only operate on cached posts
            const key = keys[i]!;
            if (!key.startsWith(this.keyPrefix)) {
                continue;
            }

            // Delete stale posts only
            if (staleOnly) {
                const post = this.storage.get(key);
                const staleTime = this.minutesStale * 60 * 1000;
                const isStale = post && Date.now() - post.updated_at > staleTime;
                if (isStale) {
                    this.storage.delete(key);    
                }
            // Delete all posts
            } else {
                this.storage.delete(key);
            }            
        }        
    }

    // Set the post on local storage
    _set(post: PostType) {
        post.updated_at = Date.now();
        this.storage.set(this._key(post.slug), post);
    }

    // Prefix key for ease finding the posts
    _key(slug: string) {
        return this.keyPrefix + slug;
    }

}