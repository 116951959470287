import { Link, useNavigate } from 'react-router-dom';
import URLS, { getHomeUrlWarning } from 'urls';
import API from 'utils/api';

/**
 * Loging warnings to display above content on home.
 */
export default function HomeWarnings(
    { warning, user }: { warning: string | null, user: UserType }
) {

    const navigate = useNavigate();
    
    function resendVerificationLink() {
        // Async send the email
        new API().sendEmailVerificationLink()
        // Redirect back home with a warning
        const url = getHomeUrlWarning('reverify');
        navigate(url);
    }

    const auth = (
        <Link to={URLS('login')} className="banner">
            <div className="warning">This link has expired. Tap for a <u>new link</u>.</div>
        </Link>
    )

    const otp = (
        <div className="info-branded">Please check your email to log in. A unique login link has been sent to your email.</div>
    )
    const logout = (
        <div className="banner">
            <div className="info">You've been logged out.</div>
        </div>
    )
    const verifyLinkResent = (
        <div className="banner">
            <div className="info">A new verification link has been sent.</div>
        </div>
    )
    const verifyEmail = (
        <div className="banner">
            <div className="warning">
                We sent you an email. Please verify your email address. Didn't receive a link?&nbsp;
                <button onClick={resendVerificationLink}>Tap here</button>.
            </div>
        </div>
    )
    const cancelSubscription = (
        <div className="banner">
            <div className="error">Your subscription has been canceled.</div>
        </div>
    )
    const closeAccount = (
        <div className="banner">
            <div className="error">Your account has been closed.</div>
        </div>
    )

    switch(warning) {
        case 'auth':
            return auth
        case 'otp':
            return otp
        case 'reverify':
            return verifyLinkResent
        case 'logout':
            return logout
        case 'cancelSubscription':
            return cancelSubscription
        case 'close-account':
            return closeAccount
        default:
            // Unverified email for logged in user
            if (user.id && !user.is_email_verified) {
                return verifyEmail
            }
            return null
    }
}
