import { lazy } from 'react';
import Settings from 'utils/settings';
// Async load tinymce so that it only loads in this moduel
const TinyEditor = lazy(
    // @ts-ignore
    () => import('@tinymce/tinymce-react')
    .then(module => ({ default: module.Editor }))
);

/**
 * Content editor - tinymce implementation
 */
export default function ContentEditor( { post, updatePostAction }:
{
    post: Partial<PostType>,
    updatePostAction: Function
}) {

    function initEditor() {
        // Set native browser spell check to true on the main editor div
        const div: HTMLDivElement | null = document.querySelector('div.mce-content-body');
        div?.setAttribute('spellcheck', 'true');
        div?.setAttribute('tabIndex', '3');
    }

    function editorChange(content: string) {
        updatePostAction(content);
    }


    // Add a keyboard shortcut to the editor html tags, e.g. h1, h2, h3, p
    function addTagShortcut(editor: any, tag: string) {
        const selected_content = editor.selection.getContent() || '';
        editor.execCommand("mceInsertContent", 0, '<' + tag + '>' + selected_content + '</' + tag + '>');
    }

    return (
        <TinyEditor
            onInit={initEditor}
            tinymceScriptSrc={Settings.GET_REACT_APP_STATIC_URL() + 'tinymce/tinymce.min.js'}
            initialValue={post.content}
            scriptLoading={{ async: true }}
            onEditorChange={(newValue: string, editor: any) => (
                editorChange(newValue)
            )}
            init={{
                menubar: false,
                inline: true,
                statusbar: false,
                image_caption: true,
                image_advtab: true,
                relative_urls: false,
                plugins: [
                    'autolink',
                    'autoresize',
                    'codesample',
                    'link',
                    'lists',
                    'media',
                    'table',
                    'image',
                    'quickbars',
                ],
                branding: false,
                toolbar: false,
                quickbars_insert_toolbar: 'h1 h2 h3 blocks | bullist numlist blockquote hr | table image media | codesample',
                quickbars_selection_toolbar: 'bold italic underline | h1 h2 h3 blocks | bullist numlist blockquote quicklink codesample table',
                quickbars_image_toolbar: 'alignleft aligncenter alignright | styles',
                contextmenu: 'cell row column | image | styles',
                placeholder: 'Start typing...',

                // Customize the formats shown in the block dropdown so that we can add caption
                block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Preformatted=pre; Caption=caption',
                formats: {
                    caption: { inline: 'span', classes: [ 'caption' ] },
                },
                

                // Override to allow svgs
                images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',

                // Allow all of the elements needed to render an svg natively (otherwise tiny will strip the html for safety)
                // this allows us to paste an svg as code
                extended_valid_elements: 'svg[*],style,line[*],path[*],text[*],g[*],rect[*],circle[*]',

                // Custom styles that show up in the `styles` menu, e.g. in quickbars_image_toolbar.
                // For example: the image-wide style wraps the element in a <span class="img-wide">...</span>
                style_formats: [
                    { name: 'image-wide', title: 'Image wide', inline: 'span', classes: [ 'img-wide' ] },
                    { name: 'image-inline', title: 'Image inline', inline: 'span', classes: [ 'img-inline' ] },
                    { name: 'callout', title: 'Callout', inline: 'span', classes: [ 'callout' ] },
                    { name: 'download-button', title: 'Download button', inline: 'span', classes: [ 'file-download' ] },
                ],

                // Add keyboard shortcuts
                setup: function (editor: any) {
                    // html tag shortcuts
                    editor.addShortcut('meta+shift+1', 'h1', function(){
                        addTagShortcut(editor, 'h1');
                    });
                    editor.addShortcut('meta+shift+2', 'h2', function(){
                        addTagShortcut(editor, 'h2');
                    });
                    // Conflicts with Mac OS screenshot. Use the default ctrl+option+3 built in to tiny
                    // editor.addShortcut('meta+shift+3', 'h3', function(){
                    //     addTagShortcut(editor, 'h3');
                    // });
                    editor.addShortcut('meta+shift+p', 'p', function(){
                        addTagShortcut(editor, 'p');
                    });
                    // lists
                    editor.addShortcut('meta+shift+8', 'UnorderedList', function(){
                        editor.execCommand('InsertUnorderedList');
                    });
                    editor.addShortcut('meta+shift+7', 'OrderedList', function(){
                        editor.execCommand('InsertOrderedList');
                    });

                    // // Comments: for implementation later
                    // editor.ui.registry.addButton('comment', {
                    //     icon: 'comment-add',
                    //     onAction: function (_: any) {
                    //         // Get the text in the selection
                    //         const selection = editor.selection.getContent();
                    //         console.log(selection);
                    //         // Gets the html node, e.g. the entire html dom object that the selection is in
                    //         const node = editor.selection.getNode();
                    //         console.log(node);
                    //         // Replace the content wrapped in a span could use to reference comments.
                    //         // Doesn't work in practice this simply, bc the selection could be multiple
                    //         // dom objects and you need to preserve that
                    //         editor.insertContent(`<span id="comment-id">${selection}</span>`);
                    //     }
                    // });
                }
            }}

        />
    )    
}

export function cleantSVGs(content: string): string {

    // Operate on a new document of just the content, not the actual document
    const parser = new DOMParser();
    let doc = parser.parseFromString(content, 'text/html');

    // Iterate over all of the rendered tweets
    doc.querySelectorAll('svg').forEach((svg) => {
        svg.removeAttribute('width');
        svg.removeAttribute('height');
        svg.removeAttribute('font-size');
        svg.setAttribute('font-family', 'Roboto Flex, sans-serif');
    });    

    return doc.documentElement.innerHTML
}