import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import URLS, { getHomeUrlWarning } from 'urls';
import { useLoginRedirect } from 'utils/hooks';
import showModal from 'components/Modal';
import { hideLoader, showLoader } from 'components/Loader';
import API from 'utils/api';
import { PostCache } from 'storage/postcache';



export default function CancelSubscription() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    const navigate = useNavigate();
    useLoginRedirect(userPromise);

    async function cancelConfirm() {
        showModal({
            title: 'Are you sure?',
            body: 'Your subscription is already paid. Are you sure you want to cancel your subscription? You’ll lose access to all of Foundation immediately.<br /><br />You will not receive a refund. This cannot be undone.',
            color: 'red',
            callBack: _handleCancel,
        })

        async function _handleCancel() {
            // Cancel subscription blocking and clear post cache to bring back paywall
            showLoader()
            new PostCache().deleteAll({ staleOnly: false })
            await new API().cancelSubscription()
            hideLoader();
            navigate(getHomeUrlWarning('cancelSubscription'))
        }
    }

    return (
        <div className="settings cancel-subscription">
            <h2>Subscription</h2>

            <h5>Cancel subscription</h5>
            <div>
                If you no longer want to subscribe, you can cancel your paid subscription. Your service will continue until the subscription you’ve already paid for expires.
                <br /><br />
                You have currently paid for a subscription through your next billing cycle.
            </div>

            <div className="actions">
                <button onClick={cancelConfirm}>Cancel subscription</button>
                <Link to={URLS('accountCancel')} className="muted">Close account</Link>
            </div>

        </div>
    )
}