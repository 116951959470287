import { MultilinePlaceholder } from 'components/common';
import { Suspense } from 'react';
import { Await, Link, useOutletContext, useSearchParams } from 'react-router-dom';
import URLS from 'urls';
import { useLoginRedirect } from 'utils/hooks';
import PaymentSettings from 'pages/user/PaymentSettings';
import { showPaywall } from 'utils/permissions';



export default function SubscriptionSettings() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    const [searchParams] = useSearchParams();
    // Only show cancel link if came from Settings (not Account)
    const canCancel = searchParams.get('source') === 'settings';

    useLoginRedirect(userPromise);

    return (
        <div className="settings">
            <h2>Subscription settings</h2>

            <h5>Payment method</h5>

            <Suspense fallback={<MultilinePlaceholder numLines={3} />}>
                <Await resolve={userPromise}>
                    {(user) => 
                    <>
                        <PaymentSettings user={user} />

                        {(canCancel && !showPaywall(user)) &&
                        <div>
                            <Link to={URLS('subscriptionSettingsCancel')} className="muted">End subscription</Link>
                        </div>}
                    </>}
                </Await>
            </Suspense>


        </div>
    )
}