/**
 * Placeholder while we wait for the post to load
 */
export default function PostPreview() {
    return (
        <>
            <br />
            <div className="placeholder-content-shimmer">
                <div className="shimmerBG content-medium"></div>
                <div className="shimmerBG content-huge"></div>
            </div>
            <br /><br />
            <div className="post-content">
                <div className="placeholder-content-shimmer">
                    <PostPreviewSnippet />
                </div>
            </div>
        </>
    )
}

export function PostPreviewSnippet() {
    let snippet = [];
    for (var i=0; i<4; i++) {
        for (var j=0; j<5; j++) {
            const key = String(i) + '_' + String(j);
            snippet.push(
                <div className="shimmerBG content-line" key={key}></div>
            );
        }
        snippet.push(
            <div className="shimmerBG content-line end-line" key={String(i)}></div>
        );
    }
    return <>{snippet}</>;

}