import { Await, useNavigate, useOutletContext } from 'react-router-dom';
import { getHomeUrlWarning } from 'urls';
import { useLoginRedirect } from 'utils/hooks';
import showModal from 'components/Modal';
import API from 'utils/api';
import { hideLoader, showLoader } from 'components/Loader';
import { PostCache } from 'storage/postcache';
import { Suspense } from 'react';



export default function CloseAccount() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    const navigate = useNavigate();
    useLoginRedirect(userPromise);

    function closeConfirm() {
        showModal({
            title: 'Are you sure?',
            body: 'Are you sure you want to close your account? You’ll lose access to all of Foundation.<br /><br />You will not receive a refund. This cannot be undone.',
            color: 'red',
            callBack: _handleDeactivate,
        })

        async function _handleDeactivate() {
            showLoader();
            new PostCache().deleteAll({ staleOnly: false })
            await new API().deactivateUser();
            hideLoader();
            navigate(getHomeUrlWarning('close-account'));
        }
    }

    return (
        <div className="settings cancel-subscription">
            <h2>Account</h2>

            <h5>Close account</h5>
            <div>
                If you no longer want to keep your account open, you can close your account.
                <br /><br />
                <Suspense>
                    <Await resolve={userPromise}>
                        {(user) => (
                            user.is_paying_subscriber
                            ? <>You have currently paid for a subscription through your next billing cycle. If you keep your account open, your service will continue until the subscription you’ve already paid for expires.</>
                            : <>When you close your account, you’ll lose access to all of the content on Foundation. This cannot be undone.</>
                        )}
                    </Await>
                </Suspense>                
            </div>

            <div className="actions">
                <button onClick={closeConfirm}>Close account</button>
            </div>

        </div>
    )
}