import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showModal from 'components/Modal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import URLS from 'urls';
import AppStorage, { SessionStorage } from 'utils/appstorage';


/**
 * Pricing selector for annual and monthly pricing scheme, e.g. ma_9-99_69-99
 */
export default function PricingAnnualMonthly({ pricing }: { pricing?: DictType }) {

    const [isAnnual, setIsAnnual] = useState(true);
    const navigate = useNavigate();

    // Memoize the selectAnnual function so that we can include it as a dependency in useEffect
    const selectAnnual = useCallback(() => {
        const appStorage = new AppStorage();
        appStorage.set(appStorage.keys.subscription_is_annual, true);
        appStorage.set(appStorage.keys.subscription_price, pricing?.scheme.annual_price);
        setIsAnnual(true);
    }, [pricing]);

    function selectMonthly() {
        const appStorage = new AppStorage();
        appStorage.set(appStorage.keys.subscription_is_annual, false);
        appStorage.set(appStorage.keys.subscription_price, pricing?.scheme.monthly_price);
        setIsAnnual(false);
    }
    
    // Save pricing info on local storage on first load
    useEffect(() => {
        selectAnnual();
    }, [selectAnnual]);

    function showFreeOption() {
        showModal({
            title: "Are you sure?",
            body: "You’ll only have access to select articles (about one per month), and no access to in depth guides on building companies, or any other resources.",
            color: 'red',
            callBack: _navigate,
        });

        async function _navigate() {
            // If came from a post paywall, redirect to the post
            const sessionStorage = new SessionStorage();
            const postSlug = sessionStorage.get(sessionStorage.keys.login_redirect_post_slug);
            if(postSlug) {
                navigate(URLS('post', 'slug', postSlug));
                return
            }
            // Otherwise redirect home
            navigate(URLS('home'));
        }        
    }    

    // Value props to show on the pricing options
    const valueProps = [
        'In depth guides to building, owning and running profitable companies.',
        'Weekly newsletter on tech and money, from CEOs and insiders.',
        'Original data, trends and charts.',
        `42% cheaper than monthly.`,
    ]

    return (
        <>
        {/* Annual */}
        <div className={`option annual ${isAnnual ? "selected" : "unselected"}`} onClick={selectAnnual}>
            <div className="pure-g">
                <div className="pure-u-3-5">
                    <h4>Annual</h4>                        
                </div>
                <div className="pure-u-2-5">
                    <span className="badge red">Popular</span>
                </div>
            </div>
            <h6>
                {pricing ?
                <>
                    ${pricing?.scheme?.annual_price / 100}/year
                    <span>(${(pricing?.scheme?.annual_price / 1200).toFixed(2)}/month)</span>
                </> 
                : 
                <div className="placeholder-content-shimmer">
                    <div className="shimmerBG darker pricing"></div>
                </div>}
            </h6>

            <div className="value-props">
                {valueProps.map((item: string, index: number) => (
                    <div className="pure-g item" key={index}>
                        <div className="pure-u-1-24"><FontAwesomeIcon icon={faCheckCircle} className="icon" /></div>
                        <div className="pure-u-23-24 text">{item}</div>
                    </div>
                ))}
            </div>

        </div>

        {/* Monthly */}
        <div className={`option monthly ${isAnnual ? "unselected" : "selected"}`} onClick={selectMonthly}>
            <h4>Monthly</h4>
            <h6>
                {pricing ?
                <>${pricing?.scheme?.monthly_price / 100}/month</> 
                : 
                <div className="placeholder-content-shimmer">
                    <div className="shimmerBG darker pricing"></div>
                </div>}
            </h6>

            {!isAnnual &&
            <div className="value-props">
                {valueProps.slice(0, 3).map((item: string, index: number) => (
                    <div className="pure-g item" key={index}>
                        <div className="pure-u-1-24"><FontAwesomeIcon icon={faCheckCircle} /></div>
                        <div className="pure-u-23-24 text">{item}</div>
                    </div>
                ))}
            </div>}

        </div>     
        
        {/* Free */}
        <button onClick={showFreeOption} className="free-option">Get free articles only</button>
        </>
    )
}