import { Link } from 'react-router-dom';
import URLS from 'urls';

export default function NoPage() {
    return (
        <div>
            <h3>You must be lost...</h3>
            <p>Seems like you've landed here by accident. Nothing to see here. Take me <Link to={URLS('home')} className="underline">home</Link>.</p>
        </div>
    );
};

