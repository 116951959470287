/*
 * HTML based editor for the post content.
 */
export default function HTMLEditor({ post, updatePostAction } : {
    post: Partial<PostType>,
    updatePostAction: Function
}) {

    function onEditorChange() {
        const content = document.querySelector('.html-editor') as HTMLPreElement;
        updatePostAction(content.innerText);
    }

    return (
        <pre
            className="html-editor"
            contentEditable={true}
            suppressContentEditableWarning={true}
            onInput={onEditorChange}
        >
            <pre>
                {post.content}
            </pre>
        </pre>
    )
}
