import { Suspense, useEffect } from 'react';
import {
    Outlet,
    useLoaderData,
    ScrollRestoration,
    useLocation,
    Await,
    useOutletContext,
    useSearchParams,
} from 'react-router-dom';
import NavBar from 'components/navbar/NavBar';
import API from 'utils/api';
import { Paywall } from 'pages/home/Paywall';
import Banner from 'components/Banner';
import Loader from 'components/Loader';
import { ModalHolder } from 'components/Modal';
import Footer from 'pages/base/Footer';
import { storeUtmParams } from 'utils/advertising';
import { setPageMetaData } from 'utils/uihelpers';


/**
 * Render the base layout of the site, loaded by every page
 */
export function LayoutBase() {

    const { userPromise } = useLoaderData() as { userPromise: UserType };

    const [searchParams] = useSearchParams();

    // Run analytics on page load/change (trigger useEffect on page change.)
    let location: Partial<Location> = useLocation();
    useEffect(() => {
        new API().identify(window.location.href);
    }, [location]);

    // Store search params for attribution, to be used by subscribe
    storeUtmParams(searchParams)

    // Set all page meta data to default settings
    setPageMetaData({})

    // Add a global listener on home scrolling to fade in the paywall
    window.addEventListener('scroll', handleScroll);

    function handleScroll() {
        const scrollY = window.scrollY;
        const paywall = document.querySelector('.paywall-drawer');
        // Show when you've scrolled down a bit
        const scrollMin = window.screen.width > 568 ? 225 : 300;
        scrollY > scrollMin ? paywall?.classList.add('show') : paywall?.classList.remove('show');
    }

    return (
        <>
        {/* Fixed banner below the nav that can be filled by for feedback to the user */}
        <Banner />

        {/* Modal that can be animated in and updated */}
        <ModalHolder />        

        {/* Loader to show during blocking calls */}
        <Loader />

        <div className="content-wrapper">
            {/* Navigation */}
            <NavBar />

            {/* Content */}
            <div className="content">
                {/* Passing the userPromise bc only this layout has the loader */}
                <Outlet context={{userPromise}} />
            </div>

            {/* Default browser scroll behavior */}
            <ScrollRestoration />
        </div>

        <Footer />
        </>       
    )
};

/**
 * Layout for the home page
 */
export function LayoutContent({ isHome }: { isHome?: boolean }) {
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    // Categories navbar and paywall
    const HomeContent = () => (
        <>
            {/* Disabled categories until we have more content */}
            {/* Categories navigation */}
            {/* <Categories /> */}
            {/* To re-enable categories delete both navbar-bottom and navbar-spacer below */}
            <div className="navbar-bottom"></div>
            <div className="navbar-spacer-desktop"></div>

            {/* Paywalls - applicability handled in paywall */}
            <Suspense>
                <Await resolve={userPromise}>
                    {(user) => <Paywall user={user}/>}
                </Await>
            </Suspense>
        </>
    )

    // Minimal navbar bottom
    const MinimalContent = () => (
        <>
            <div className="navbar-bottom"></div>
            <div className="navbar-spacer"></div>        
        </>
    )

    return (
        <>
            {/* Home content or minimal nav */}
            {isHome ? <HomeContent /> : <MinimalContent />}

            {/* Core content */}
            <Outlet context={{userPromise}}/>
        </>
    )
};
