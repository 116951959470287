import React from 'react';
import { format } from 'date-fns'

/**
 Format date from a timestamp
 */
 export function DateFormat(props: { timestamp: number; format: string; }) {
    if(props.timestamp) {
        return (
            <>
            {format(new Date(props.timestamp * 1000), props.format)}
            </>
        );
    }
    return null
};

/**
 Wrapper to handle optional image
 */
export function Image(props: { src: string; className: string; }) {
    if(props.src) {
        return(
            <img src={props.src} className={props.className} alt="" />
        )
    }
    return null
};


/**
 Multiline placeholder, e.g. for use in settings
 */
 export function MultilinePlaceholder({numLines}: {numLines: number}) {
    return (
        <div className="placeholder-content-shimmer">
            {Array.from({ length: numLines }, (_, index) => (
                <div key={index} className="shimmerBG content-line"></div>
            ))}
        </div>        
    )
}