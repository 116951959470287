/**
 * Return true if a user should see a paywall
 */
export function showPaywall(user: UserType): boolean {
    return !user.id || !user.is_paying_subscriber;
}

/**
 * Return true if a user should see a paywall on a post
 * (taking into account whether a post is public)
 */
export function showPostPaywall(user: UserType, post: PostType): boolean {
    return !post.is_public && showPaywall(user);
} 
