import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';



// Global to hold the modal root
let modalRoot: ReactDOM.Root | null = null;

// The dom id of the modal container to build the root from
const modalID = 'global-modal';


/**
 * Public function to interact with the modal
 * Example of how to call this function:
   showModal({
       title: "Are you sure?",
       body: "This will update the url of this post, which will break any old links. It cannot be undone.",
       callBack: resetSlug,
   });
 */
export default function showModal({
    title,
    body, 
    confirmButton='Confirm',
    cancelButton='Cancel',
    color='blue',
    callBack,
    cancelCallBack,
}: {
    title: string,
    body: string,
    confirmButton?: string,
    cancelButton?: string,
    color?: string,
    callBack: Function,
    cancelCallBack?: Function,
}
) {

    const modalContent = (
        <Modal
            title={title}
            body={body}
            confirmButton={confirmButton}
            cancelButton={cancelButton}
            color={color}
            callBack={callBack}
            cancelCallBack={cancelCallBack}
        />
    )
    // Create a new root for the modal. Will get unmounted on modal close.
    const modalRootContainer: HTMLElement = document.getElementById(modalID)!;
    modalRoot = ReactDOM.createRoot(modalRootContainer);
    modalRoot.render(modalContent);
}

/**
 * Container to hold the modal that we mount a root to
 */
export function ModalHolder() {
    return <div id={modalID}></div>
}

/**
 * The modal component
 */
function Modal({
    title,
    body,
    confirmButton,
    cancelButton,
    color,
    callBack,
    cancelCallBack
} : {
    title: string,
    body: string,
    confirmButton: string,
    cancelButton: string,
    color: string,
    callBack: Function,
    cancelCallBack?: Function,
}) {

    useEffect(() => {
        // Show overlay on load
        const overlay = document.querySelector('div.modal-overlay')!;
        overlay.classList.add('show');
    }, []);

    function handleCallBack() {
        callBack();
        modalRoot!.unmount();
    }

    function close() {
        modalRoot!.unmount();
        cancelCallBack && cancelCallBack();
    }

    return (
        <>
            {/* Translucent black overlay behind the modal */}
            <div className="modal-overlay" onClick={close}></div>

            {/* Contents of the modal */}
            <div className={`modal-content ${color === 'red' && 'modal-red'}`}>
                <div className="inner-content">
                    <h3>{title}</h3>
                    <span dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className="pure-g buttons">
                    <div className="pure-u-12-24">
                        <button className="left" onClick={close}>{cancelButton}</button>
                    </div>
                    <div className="pure-u-12-24">
                        <button className="right" onClick={handleCallBack}>{confirmButton}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
