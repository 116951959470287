import { Await, Link, useNavigate, useOutletContext } from 'react-router-dom';
import URLS from 'urls';
import 'css/subscription.scss';
import { helpCenterUrl } from 'utils/settings';
import API, { hasAuthToken } from 'utils/api';
import { trackLeadExternal } from 'utils/advertising';
import AppStorage from 'utils/appstorage';
import { Suspense, useState } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signupLanguage } from 'language/signup_language';


export default function CreateUser() {

    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const navigate = useNavigate();
    const [errors, setErrors] = useState<ErrorsType>({});
    const [inProgress, setInProgress] = useState(false);

    // Track page view
    new API().trackPage('CreateUser');

    // Validate information and create user
    async function createUser() {

        // Get data
        const email = document.querySelector('input[name="email"]') as HTMLInputElement;
        const first_name = document.querySelector('input[name="first_name"]') as HTMLInputElement;
        const last_name = document.querySelector('input[name="last_name"]') as HTMLInputElement;
        const industry = document.querySelector('select[name="industry"]') as HTMLInputElement;
        const button = document.querySelector('div.create-user button') as HTMLButtonElement;

        // Client side validation
        const errors: ErrorsType = {};
        if (email === null || !email.value.includes('@')) {
            errors.email = true;
        }
        if (first_name === null || first_name.value.length < 2) {
            errors.first_name = true;
        }
        if (last_name === null || last_name.value.length < 2) {
            errors.last_name = true;
        }
        if (industry === null || industry.value === 'placeholder') {
            errors.industry = true;
        }
        if (errors.email || errors.first_name || errors.last_name || errors.industry) {
            setErrors(errors);
            return
        }

        // Disable button to avoid double user creation on double tap
        button.disabled = true;
        setInProgress(true);

        // Blocking user creation call
        // Will also auto log in the user if account created
        const user = await new API().getOrCreateUser({
            email: email.value,
            first_name: first_name.value,
            last_name: last_name.value,
            industry: industry.value,
        });
        const userCreated = Boolean(user?.token);

        // Error creating the user
        if (user.message) {
            errors.message = user.message;
            button.disabled = false;
            setInProgress(false);
            setErrors(errors);
            return
        }

        // If user not created and not logged in (no token), send login email and go to loginredirect page
        if (!userCreated && !hasAuthToken()) {
            new API().sendOTPToken(email.value);
            // Note: error should never happen bc we just validated the email with
            // getOrCreateUser, so proceed optimistically
            navigate(URLS('loginConfirmExistingUser'));
            return
        }

        // Track create user event to external ad platform
        trackLeadExternal();

        // Delete last_visit local timestamp to force an identify call
        const appStorage = new AppStorage();
        appStorage.delete(appStorage.keys.last_visit);
        
        // User created, proceed to pricing
        // navigate(URLS('pricing'));
        window.location.href = URLS('pricing');
    }

    // Update user information via put
    function updateUser() {
        const first_name = document.querySelector('input[name="first_name"]') as HTMLInputElement;
        const last_name = document.querySelector('input[name="last_name"]') as HTMLInputElement;
        const industry = document.querySelector('select[name="industry"]') as HTMLInputElement;

        // Client side validation
        const errors: ErrorsType = {};
        if (first_name === null || first_name.value.length < 2) {
            errors.first_name = true;
        }
        if (last_name === null || last_name.value.length < 2) {
            errors.last_name = true;
        }
        if (industry === null || industry.value === 'placeholder') {
            errors.industry = true;
        }
        if (errors.first_name || errors.last_name || errors.industry) {
            setErrors(errors);
            return
        }

        // Update user info (async, optimistically)
        new API().updateUser({
            first_name: first_name.value,
            last_name: last_name.value,
            industry: industry.value,
        });

        // User created, proceed to pricing
        navigate(URLS('pricing'));
    }

    return (
        <Suspense>
        <Await resolve={userPromise}>
            {(user) => 

            <div className="desktop-utilities-content create-user">
                
                <div className="header">
                    {user.id ?
                    // Logged in
                    <>
                        <h2 className="header">About you</h2>
                        <p>{signupLanguage.description}</p>
                    </>
                    :
                    // Not logged in
                    <>
                        <h2 className="header">Welcome!</h2>
                        <p>{signupLanguage.description}</p>
                    </>
                    }
                </div>

                <div className="body">

                    <ErrorAlert errors={errors}/>

                    <div className="form">
                        {/* Only show email input to logged out user */}
                        {!user.id &&
                        <>
                            <label>Email address</label>
                            <input type="email" name="email" placeholder="Email" className={`basic ${errors?.email && "input-error"}`} autoFocus />
                        </>}

                        <label>Name</label>
                        <div className="pure-g">
                            <div className="pure-u-12-24">
                                <input type="text" name="first_name" placeholder="First name" className={`basic left ${errors?.first_name && "input-error"}`} autoFocus={user.id ? true : false}/>
                            </div>
                            <div className="pure-u-12-24">
                                <input type="text" name="last_name" placeholder="Last name" className={`basic right ${errors?.last_name && "input-error"}`} />
                            </div>
                        </div>

                        <label>What industry do you work in?</label>
                        <select name="industry" defaultValue="placeholder" className={`basic ${errors?.industry && "input-error"}`} >
                            <option disabled key='0' value="placeholder">Select industry</option>
                            {industries.map((opt: string) => (
                                <option key={opt.toLocaleLowerCase()} id={opt.toLocaleLowerCase()} value={opt}>{opt}</option>
                            ))}
                        </select>                    

                        {/* Only show terms to logged out user */}
                        {!user.id ?
                        <span className="disclosure">I agree to the <Link to={helpCenterUrl('terms')} target="_blank">Terms of Use</Link> and <Link to={helpCenterUrl('privacy')} target="_blank">Privacy Policy</Link>.</span>
                        : <br />}

                        <button type="submit" className="btn-primary" onClick={user.id ? updateUser : createUser}>
                            Continue
                            {inProgress &&
                            <FontAwesomeIcon icon={faCircleNotch} className="spinner fa-spin fast-spin" />}
                        </button>
                    </div>

                </div>

            </div>
            }
        </Await>
    </Suspense>

    )
}

// Server side error message
function ErrorAlert({ errors }: { errors: ErrorsType }) {
    if (errors?.message) {
        return <div className="error">{errors?.message}</div>;
    }
    return null;
}

// Form validation errors
type ErrorsType = {
    email?: boolean;
    first_name?: boolean;
    last_name?: boolean;
    industry?: boolean;
    message?: string;
}

// List of industries to display in the create user form
const industries = [
    'Consulting',
    'Education',
    'Finance',
    'Healthcare and medical',
    'Hospitality',
    'Legal',
    'Operations',
    'Media and communication',
    'Marketing',
    'Real estate',
    'Retail',
    'Sales',
    'Science',
    'Startups',
    'Technology',
    'Venture capital',
    'Other',
]