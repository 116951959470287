import { Suspense } from 'react';
import { Await, useLoaderData, useOutletContext } from 'react-router-dom';
import PostPreview from 'pages/postlists/PostPreview';
import { Placeholder } from 'pages/postlists/Bookmarks';
import { usePublisherRedirect } from 'utils/hooks';
import 'css/postlists.scss';


/**
 * Page of all drafts for publishers
 */
export default function DraftPosts() {
    const { draftsPromise } = useLoaderData() as { draftsPromise: Array<PostType> };
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    usePublisherRedirect(userPromise);

    return (
        <div className="article-list">
            <h2 className="header">Drafts</h2>
            <Suspense fallback={<Placeholder />}>
                <Await resolve={userPromise}>
                    {(user) =>
                        <Await resolve={draftsPromise}>
                            {(posts) => (
                                posts.length > 0 ?
                                posts.map((post: PostType) => (
                                    <PostPreview post={post} key={post.id}/>
                                ))
                                : <p className="empty">No drafts yet. Your unpublished drafts will show up here.</p>
                            )}
                        </Await>
                    }
                </Await>
            </Suspense>
        </div>
    )
}
