import { MouseEventHandler, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowUpFromBracket,
    faEllipsis,
    faBookmark as faBookmarkSolid,
    faBars,
    faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import AppStorage from 'utils/appstorage';
import API from 'utils/api';
import PopOver from 'components/PopOver';
import { showBanner } from 'components/Banner';
import { PostTypeEditor, PublisherTools, VersionHistory } from 'pages/post/editor/PublisherTools';
import WebShareMenu from 'pages/post/WebShareMenu';
import 'css/menus.scss';


/**
 * Render a toolbar on the post with share, save, and publisher tools
 */
export default function PostToolbar({
    post,
    showTools,
    isEditing = false,
    onUpdatePostField,
}: {
    post: Partial<PostType>,
    showTools?: boolean,
    isEditing?: boolean,
    onUpdatePostField?: MouseEventHandler<HTMLButtonElement>,
}
) {

    const { bookmarkPromise } = useLoaderData() as { bookmarkPromise: BookmarkType };
    
    // Bookmark can equal null, true, or false. Null if it hasn't loaded yet.
    const [bookmarked, setBookmarked] = useState<boolean | null>(null);

    function updateBookmark() {
        // If user doesn't have an auth token, show banner to sign up
        const appStorage = new AppStorage();
        const hasAuthToken = Boolean(appStorage.get(appStorage.keys.token));
        if (!hasAuthToken) {
            showBanner("You can bookmark an article once you sign up.")
            return
        }
        // Optimistically update ui; call server async
        const newState = !bookmarked;
        new API().bookmarkPost(post.id!, newState);
        setBookmarked(newState);
    }

    useEffect(() => {
        // Rerender the component when the promise resolves
        // Do this manually in the useEffect rather than with Await bc we
        // have to set bookmarked state dynamically on async load.
        if(bookmarkPromise) {
            // In logged out mode, we pass a dummy promise of false
            // (see postLoader), so handle
            if ('is_bookmarked' in bookmarkPromise) {
                setBookmarked(bookmarkPromise.is_bookmarked);
            // Wait for the API
            } else {
                (bookmarkPromise as any).then((bookmark: DictType) => {
                    setBookmarked(bookmark.is_bookmarked);
                });    
            }
        }
    }, [bookmarkPromise]);

    // Share on mobile
    function shareMobile() {
        const shareData: ShareData = {
            title: post.title!,
            url: window.location.href,
        };
        navigator.share(shareData);
    }

    const publisherTools = (
        <PublisherTools
            post={post}
            isEditing={isEditing}
            onUpdatePostField={(e: any) => onUpdatePostField!(e)}
        />
    )

    function ButtonPlaceholder() {
        return (
            <>
            <FontAwesomeIcon icon={faBookmark} />
            {/* <span>Save</span> */}
            <span className="placeholder-content-shimmer">
                <span className="shimmerBG content-button">&nbsp;</span>
            </span>        
            </>
        )
    }
    return (
        <div className="pure-g post-toolbar">

            <div className="pure-u-1 pure-u-md-4-5">

                {/* Share */}
                {
                    Boolean(navigator.share)
                    ?  // Mobile sharing
                    <button className="button share" onClick={shareMobile}>
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                        <span>Share</span>
                    </button>
                    :  // Web sharing
                    <PopOver content={<WebShareMenu post={post} />}>
                        <button className="button share">
                            <FontAwesomeIcon icon={faArrowUpFromBracket} />
                            <span>Share</span>
                        </button>                        
                    </PopOver>
                }

                {/* Bookmark */}
                <button className="button" onClick={updateBookmark} disabled={bookmarked === null} >
                    {bookmarked === null ? <ButtonPlaceholder /> : (
                        bookmarked ? 
                        <><FontAwesomeIcon icon={faBookmarkSolid} /><span>Unsave</span></>
                        : <><FontAwesomeIcon icon={faBookmark} /><span>Save</span></>
                    )}
                </button>

                {/* Publisher tools */}
                {showTools ?
                <>
                    {/* Change post type - editing mode only */}
                    {isEditing &&
                    <PopOver content={<PostTypeEditor post={post} onUpdatePostField={onUpdatePostField!} />}>
                        <button className="button">
                            <FontAwesomeIcon icon={faBars} />
                            <span>Type</span>
                        </button>
                    </PopOver>}
                    
                    {/* View version history - editing mode only */}
                    {isEditing &&
                    <PopOver content={<VersionHistory post={post} />}>
                        <button className="button">
                            <FontAwesomeIcon icon={faClockRotateLeft} />
                            <span>History</span>
                        </button>
                    </PopOver>}

                    {/* Tools menu */}
                    <PopOver content={publisherTools}>
                        <button className="button">
                            <FontAwesomeIcon icon={faEllipsis} />
                            <span>More</span>
                        </button>
                    </PopOver>
                </>
                : null}

            </div>            

            {/* Badges showing post state */}
            {showTools &&
            <div className="pure-u-1">
                {!post.is_active ? <span className="badge pink">Archived</span> : null}
                {!post.is_published ? <span className="badge">Not published</span> : null}
                {!post.is_visible ? <span className="badge">Hidden</span> : null}
                {!post.can_be_primary ? <span className="badge">Not primary</span> : null}
                {post.is_public ? <span className="badge">Public</span> : null}
            </div>}

        </div>
    )
}
