import { Form, redirect, useActionData } from 'react-router-dom';
import URLS from 'urls';
import API from 'utils/api';
import 'css/subscription.scss';

/*****************************************************************************
 * Render
 *****************************************************************************/

/**
 * Render the email input form
 */
export default function Login() {

    let errors = useActionData() as ErrorsType;

    return (
        <div className="desktop-utilities-content login-create-form">
            <div className="body">
                <h2 className="header">Log in</h2>

                <ErrorAlert errors={errors}/>

                <label>Email address</label>
                <Form action={URLS('login')} method="post">
                    <input type="email" name="email" placeholder="Email" className="basic" autoFocus />
                    <span className="note">You’ll receive an email with a unique log in link. Tap on the link in the email to log in.</span>
                    <button type="submit" className="btn-primary">Log in</button>
                </Form>
            </div>

        </div>
    )
}

/**
 * Render the error box if there are errors
 */
function ErrorAlert({ errors }: { errors: ErrorsType }) {
    if (errors?.email) {
        return <div className="error">Please enter a valid email address.</div>;
    }
    return null;
}

/*****************************************************************************
 * Data
 *****************************************************************************/

/**
 * Form validation errors
 */
type ErrorsType = {
    email?: boolean;
}

/**
 * Validate and submit the form 
 */
export async function loginForm({ request }: { request: Request }) {

    // Get data from form
    const formData = await request.formData();
    const email = formData.get('email');

    // Validate data
    const errors: ErrorsType = {};
    if (typeof email !== 'string' || !email.includes('@')) {
        errors.email = true;
    }

    // Get the user and send an otp link via email
    const response = await new API().sendOTPToken(email!.toString());
    if (response.message) {
        errors.email = true;
    }
    if (errors.email) {
        return errors;
    }

    return redirect(URLS('loginConfirm'));
}