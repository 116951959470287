import 'css/subscription.scss';

export default function LoginConfirm() {
    return (
        <div className="desktop-utilities-content login-create-form">
            <div className="body">
                <h2 className="header">Log in</h2>

                <label>Email address</label>
                <div className="info">
                    A unique link has been sent to your email.<br />Please check your email to log in.
                </div>
            </div>
        </div>
    )
}
