import showModal from 'components/Modal';
import { PostCache } from 'storage/postcache';
import { getHomeUrlWarning } from 'urls';
import API from 'utils/api';

/**
 * Async/optimistically log out and redirect home
 */
export function logout() { 

    showModal({
        title: "Are you sure?",
        body: "Are you sure you want to log out?",
        callBack: _logout,
    });    

    function _logout() {
        // Log out async
        new API().logout();
        // Delete all cached posts to bring back the paywall
        new PostCache().deleteAll({ staleOnly: false })
        // Optimistically redirect home
        window.location.href = getHomeUrlWarning('logout');
    }

}
