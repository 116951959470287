import { isDarkMode } from 'utils/uihelpers';

const appearance: DictType = {
  theme: 'stripe',

  variables: {
        fontFamily: ' "Roboto Flex", sans-serif',
        fontSizeBase: '16px',
        fontLineHeight: '1.5',
        colorDanger: '#DA1B1B',
        colorPrimaryText: '#222222',
        colorTextPlaceholder: '#6D6E78',
  },
  rules: {
    '.TabIcon, .TabIcon--selected, .TabIcon--selected:focus, .TabIcon--selected:hover': {
        fill: 'var(--colorPrimaryText)',
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        color: 'var(--colorTextPlaceholder)',
        backgroundColor: '#FFFFFF',
        borderColor: 'var(--colorTextPlaceholder)',
        boxShadow: '0 0 0 0.5px var(--colorTextPlaceholder), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Label': {
        fontWeight: '600',
        fontSize: '13px',
        padding: '5px 0px 3px 0px',
        color: 'var(--colorTextPlaceholder)',
    },
  }
};

// Deep copy original
let appearanceDark = JSON.parse(JSON.stringify(appearance));
appearanceDark['rules']['.Tab--selected, .Tab--selected:focus, .Tab--selected:hover']['backgroundColor'] = '#F7F5F5';
appearanceDark['rules']['.Label']['color'] = '#777777';
appearanceDark['rules']['.Input'] = {'backgroundColor': '#F7F5F5'};


export function getStripeCheckoutConfig(amount: number): DictType {

    return {
        mode: 'subscription',
        amount: amount,
        currency: 'usd',
        fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@400;500;600;700;800&display=swap'
        }],
        appearance: isDarkMode() ? appearanceDark : appearance,
    }
};