import Settings from 'utils/settings';

/**
 * Trim paragraph to the nearest word and add ellipses
 */
export function ellipsify(str: string | null, maxLen: number) {
    if(!str) {
        return '';
    }
    if (str.length <= maxLen) {
      return str;
    }
    const truncatedStr = str.substr(0, maxLen);
    const lastSpaceIndex = truncatedStr.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
      return truncatedStr + '...';
    }
    return truncatedStr.substr(0, lastSpaceIndex) + '...';
};


/**
 * Convert a string into a slug
 */
export function slugify(title: string) {
    const slug = title.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');
    return slug;
};


/**
 * Strip tags from html and return a string
 */
export function stripHtmlTags(html: string) {
    var regex = /(<([^>]+)>)/gi;
    return html.replace(regex, '');
  }

  
/**
 * Manager a contenteditable placeholder for firefox
 * The &[placeholder]:empty::before doesnt work on firefox.
 */
export function handleContentEditableBlur(element: HTMLElement) {
    if (!element!.textContent!.trim().length) {
        element.innerHTML = '';
    }        
}

/**
 * Set page metadata and social metadata for a page
*/
export function setPageMetaData({
    title,
    description,
    type,
    image,
    url,
    twitterCard,
} : {
    title?: string,
    description?: string,
    type?: string,
    image?: string,
    url?: string,
    twitterCard?: string,
}) {
    // Set title dynamically
    document.title = title ? title : Settings.COMPANY_NAME!;

    // NOTE: disabled all of this dynamic social metadata bc crawlers don't pick it up.
    // Instead, we implemented a django view specifically for crawlers for Posts.
    // See PostCrawlerView.

    // const ogTitleTag = document.querySelector('meta[property="og:title"]');
    // const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    // const ogTypeTag = document.querySelector('meta[property="og:type"]');
    // const ogImageTag = document.querySelector('meta[property="og:image"]');
    // const ogUrlTag = document.querySelector('meta[property="og:url"]');
    // const twitterCardTag = document.querySelector('meta[name="twitter:card"]');

    // ogTitleTag?.setAttribute('content', document.title);
    // ogDescriptionTag?.setAttribute('content', stripHtmlTags(description ? description : Settings.COMPANY_DESCRIPTION!));
    // ogTypeTag?.setAttribute('content', type ? type : 'article');
    // if (image) {
    //     ogImageTag?.setAttribute('content', image);
    // }
    // ogUrlTag?.setAttribute('content', url ? url : window.location.href);
    // twitterCardTag?.setAttribute('content', twitterCard ? twitterCard : 'summary_large_image');

}

/**
 * Return true if viwe is in dark mode
 */
export function isDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

/**
 * Capital first letter of string
 */
export function capitalizeFirstLetter(str: string) {
    if(!str) {
        return ''
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Set tabIndex to -1 for all elements for which tabIndex is not
 * explicitly set. This lets us set other inputs explicitly, e.g. in EditPost
 */
export function setAllTabIndexesNegative() {
    const elements = document.querySelectorAll('*');
    elements.forEach((element) => {
        const elem = element as HTMLElement;
        if (elem.tabIndex < 1) {
            elem.tabIndex = -1;
        }
    });    
}