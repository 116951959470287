import {
    useRouteError,
    isRouteErrorResponse,
} from 'react-router-dom';
import NoPage from 'pages/base/NoPage';
import { NavBarNoData } from 'components/navbar/NavBar';


/**
 * Default error page at the root level of the layout
 * Docs: https://reactrouter.com/en/main/route/error-element
 */
export default function RootBoundary() {
    const error = useRouteError();

    // Handle the 404 here
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <NoPageWrapper />;
        }
    }
    return <Error message="Something went wrong. We're working on fixing it!" />;
}

function LayoutWrapper({ body }: { body: JSX.Element }) {
    return (
        <div>
            <NavBarNoData />
            <div className="content-wrapper">
                <div className="content">
                    <div className="navbar-bottom"></div>
                    <div className="navbar-spacer"></div>
                    {body}
                </div>
            </div>
        </div>
    )
}

function NoPageWrapper() {
    return <LayoutWrapper body={<NoPage />} />
}


function Error({ message }: { message: string }) {
    const body = (
        <>
            <h3>Error</h3>
            <p>{message}</p>                  
        </>        
    )
    return <LayoutWrapper body={body} />
}