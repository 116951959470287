import { useState } from 'react';
import API from 'utils/api';
import { PostCache } from 'storage/postcache';

/**
 * States that the uploader can be in
 */
const UploaderState = {
    empty: 'empty',
    editing: 'editing',
    preview: 'preview',
}

export default function PostImageUpload(props: {
    className: string,
    imageUrl: string,
    post: Partial<PostType>
}) {

    // Hooks

    const initialState = props.imageUrl != null ? UploaderState.preview : UploaderState.empty;
    const [state, setState] = useState(initialState);
    const [imageSrc, setImageSrc] = useState(props.imageUrl);
    const [imageHeight, setimageHeight] = useState(0);

    // Image helpers

    // Show image preview and save image to server
    async function saveImage(event: any) {

        // Clear cache on post edits
        new PostCache().delete(props.post.slug!);

        // Image preview on screen (using base64 encoding)
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            setState(UploaderState.preview);
            // Set the base64 image src for the preview
            const base64 = fileReader.result!;
            setImageSrc(base64.toString());
        };

        // Update state optimistically
        setState(UploaderState.preview);

        // Save the image
        let formData = new FormData();
        formData.append('image', event.target.files[0]);
        new API().updatePostImage(props.post, formData);

    }

    function editImage() {
        setState(UploaderState.editing);
    }
    
    function cancelEditImage(e: Event) {
        // e.preventDefault();
        setState(UploaderState.preview);
    }

    function imageLoaded(e: any) {
        setimageHeight(e.target.height);
    }

    // Components

    function Uploader() {
        return (
            <div className="image-uploader">
                {
                    imageHeight > 0
                    ? <button className="uploader" style={{height: imageHeight}}>Drag and drop an image or click to upload</button>
                    : <button className="uploader">Drag and drop an image or click to upload</button>
                }                
                <input name="image" type="file" onChange={(e) => saveImage(e)} />
                {state === UploaderState.editing &&
                <button className="edit-image-link always-visible" style={{marginTop: imageHeight / 2 + 50}} onClick={(e: any) => cancelEditImage(e)}>Cancel</button>}
            </div>
        )    
    }

    function Preview() {
        return (
            <div className="image-uploader">
                <img className={props.className} src={imageSrc} alt="" onLoad={(e) => imageLoaded(e)}/>
                <button className="edit-image-link" style={{marginTop: imageHeight / 2}} onClick={editImage}>Edit image</button>
            </div>
        )           
    }

    // Render the entire component

    function Render() {
        switch(state) {
            case UploaderState.empty:
            case UploaderState.editing:
                return <Uploader />
            case UploaderState.preview:
                return <Preview />
            default:
                return null
        }
    }
    return <Render />

}
