import { Suspense } from 'react';
import {
    Await,
    Link,
    useLoaderData,
    useOutletContext
} from 'react-router-dom';
import API from 'utils/api';
import { useLoginRedirect } from 'utils/hooks';
import { MultilinePlaceholder } from 'components/common';
import EditEmail from 'pages/user/EditEmail';
import { helpCenterUrl } from 'utils/settings';
import URLS, { getSubscribeUrl } from 'urls';
import { logout } from 'utils/auth';
import { showPaywall } from 'utils/permissions';

/**
 * Render the accounts page in settings
 */
export default function Account() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const { notificationsPromise } = useLoaderData() as { notificationsPromise: DictType };

    useLoginRedirect(userPromise);

    return (
        <div className="settings">
            <h2>Settings</h2>

            <h5>Email address</h5>
            <EditEmail />

            <h5>Notifications</h5>
            <Suspense fallback={<MultilinePlaceholder numLines={3} />}>
                <Await resolve={notificationsPromise}>
                    {(settings) =>
                        settings.keys && 
                        settings.keys.map((setting: string) => (
                            <SettingToggle
                                setting={setting}
                                name={settings[setting].name}
                                value={settings[setting].value}
                                key={setting}
                            />
                        ))
                    }
                </Await>
            </Suspense>

            <h5>Account actions</h5>
            <div className="pure-g">
                <div className="pure-u-1-1">
                    <Link to={helpCenterUrl('home')} target="_blank">Support</Link>
                </div>
                <Suspense fallback={<MultilinePlaceholder numLines={1} />}>
                    <Await resolve={userPromise}>
                        {(user) =>
                            showPaywall(user) ?
                            <>
                                <div className="pure-u-1-1">
                                    <Link to={getSubscribeUrl(user)}>Subscribe</Link>
                                </div>
                                <div className="pure-u-1-1">
                                    <Link to={URLS('accountCancel')}>Close account</Link>
                                </div>
                            </>
                            : 
                            <div className="pure-u-1-1">
                                <Link to={`${URLS('subscriptionSettings')}?source=settings`}>Update subscription</Link>
                            </div>
                    }
                    </Await>
                </Suspense>
                <div className="pure-u-1-1">
                    <button onClick={logout}>Log out</button>
                </div>
            </div>

        </div>
    )
}

/**
 * Toggle component for an email setting
 */
function SettingToggle({
    setting,
    name,
    value
}: {
    setting: string,
    name: string,
    value: boolean
}) {

    function editSetting(e: Event) {
        const checkbox = e.target as HTMLInputElement;
        new API().updateNotifications(checkbox.name, checkbox.checked);
    }

    return (
        <div className="pure-g">
            <div className="pure-u-4-5">{name}</div>
            <div className="pure-u-1-5 right">
                <label className="toggle">
                    <input 
                        type="checkbox"
                        name={setting}
                        defaultChecked={value}
                        onChange={(e: any) => editSetting(e)}
                    />
                    <span></span>
                </label>                                    
            </div>
        </div>        
    )
}