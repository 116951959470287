import { Suspense, useState } from 'react';
import { Await, useOutletContext } from 'react-router-dom';
import API from 'utils/api';
import 'css/settings.scss';
import showModal from 'components/Modal';
import { showBanner } from 'components/Banner';
import { MultilinePlaceholder } from 'components/common';

/**
 * Editable email component in account / settings
 */
export default function EditEmail() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };
    
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [updatedEmail, setUpdatedEmail] = useState('');

    /**
    * Action to handle editing and saving the email address. What it does:
    * Not in edit mode: flip to edit mode - show email input, show save/cancel buttons
    * In edit mode: save button shows a confirm model, which allows for save or cancel.
    */
    async function emailAction() {

        const emailInput = document.querySelector('input[type="email"]') as HTMLInputElement;
        const originalEmail = emailInput.defaultValue;
       
        function _showModal() {
            showModal({
                title: "Are you sure?",
                body: "Are you sure you want to change your email?",
                callBack: _updateEmail,
                cancelCallBack: cancelEdit,
            });    
        }

        async function _updateEmail() {
            // Enforce that the email was updated
            if(emailInput.defaultValue === emailInput.value) {
                cancelEdit();
                return
            }
            // Update UI optimistically
            emailInput.classList.remove('editing');
            setIsEditingEmail(false);
            // Call backend to update the email
            const data = {'email': emailInput.value};
            const user = await new API().updateUser(data);

            // Error updating email, show the message and reset email
            if(user.message) {
                showBanner(user.message);
                setUpdatedEmail(originalEmail);
                emailInput.value = originalEmail;
            // Email was updated, show feedback
            } else {
                showBanner(
                    'Please check your email. We just send you a link to verify your new email address.',
                    false
                );
                // Reset the email in case cleaned it, e.g. bc of a typo like .con or @gnail
                setUpdatedEmail(user.email);
            }

        }

        // Show modal bc the save button is visible
        if (isEditingEmail) {
            emailInput.setAttribute('readOnly', isEditingEmail.toString());
            _showModal()
        // Show editing mode bc the edit button is visible
        } else {
            emailInput.classList.add('editing');
            emailInput.removeAttribute('readOnly');
            emailInput.focus();
            setIsEditingEmail(true);
        }
        
    }

    // Cancel editing email and revert to initial state
    function cancelEdit() {
        const emailInput = document.querySelector('input[type="email"]') as HTMLInputElement;
        emailInput.classList.remove('editing');
        setIsEditingEmail(false);
        setUpdatedEmail(emailInput.defaultValue);
        emailInput.value = emailInput.defaultValue;
    }

    return (
        <Suspense fallback={<MultilinePlaceholder numLines={1} />}>
            <Await resolve={userPromise}>
                {(user) => (
                    <div className="pure-g">
                        <div className="pure-u-16-24 pure-u-md-14-24">
                            <input type="email" defaultValue={updatedEmail === '' ? user.email : updatedEmail} readOnly={!isEditingEmail}/>
                        </div>
                        <div className="pure-u-8-24 pure-u-md-10-24 right">
                            <button className="muted" onClick={cancelEdit}>{isEditingEmail ? "Cancel" : ""}</button>
                            <span>{isEditingEmail ? " · " : ""}</span>
                            <button className="muted" onClick={emailAction}>{isEditingEmail ? "Save" : "Edit"}</button>
                        </div>
                    </div>
                )}
            </Await>
        </Suspense>
    )
}
