import { useRef, useState } from 'react';
import API from 'utils/api';

/**
 * Category and topic editing component
 * Toggles between showing the category/topic as text and select inputs letting
 * you edit the inputs.
 */
export default function EditableCategory({ categories, post } : {
    categories: Array<PostTopicType>,
    post: Partial<PostType>
}) {

    const [isEditing, setIsEditing] = useState(false);
    const [category, setCategory] = useState(post.category?.name || '');
    const [topic, setTopic] = useState(post.topic?.name || '');

    const editLinkRef = useRef<HTMLButtonElement | null>(null);
    const categoryRef = useRef<HTMLSelectElement | null>(null);
    const topicRef = useRef<HTMLSelectElement | null>(null);

    // Hide the edit link
    function mouseLeave() {
        const editLink = editLinkRef.current!;
        editLink.classList.add('hidden');
        editLink.classList.remove('visible');
    }

    // Show the edit link
    function mouseEnter() {
        const editLink = editLinkRef.current!;
        editLink.classList.remove('hidden');
        editLink.classList.add('visible');
    }

    // Put the component in edit mode
    function edit() {
        setIsEditing(true);
    }

    // Update the post and save the component
    function save() {

        const categorySelect = categoryRef.current!;
        const topicSelect = topicRef.current!;
        // Update state
        setIsEditing(false);
        setCategory(categorySelect.value);
        setTopic(topicSelect.value);

        // Save to server
        const data: Partial<PostType> = {
            id: post.id,
            category: {
                id: categorySelect.options[categorySelect.selectedIndex].getAttribute('id')!,
                name: category,
            },
            topic: {
                id: topicSelect.options[topicSelect.selectedIndex].getAttribute('id')!,
                name: topic
            }
        }
        new API().updatePost(data);
    }

    function CategoryStatic() {
        return (
            <div className="topics edit-topics" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={edit}>
                <h4 placeholder="Category">{category}</h4>
                <h4> | </h4>
                <h4 placeholder="Topic">{topic}</h4>
                <button className="editable hidden" ref={editLinkRef} >Edit</button>
            </div>
        )
    }

    function CategoryEditing() {
        return (
            <div className="topics edit-topics edit-topics-selected">
                <select name="category" defaultValue={category} ref={categoryRef}>
                    <option disabled key='0'>Select category</option>
                    {categories.filter(
                        (opt: any) => opt.topic_type === 'c'
                    ).map((opt: any) => (
                        <option key={opt.id} id={opt.id} value={opt.name}>{opt.name}</option>
                    ))}
                </select>        
                <select name="topic" defaultValue={topic} ref={topicRef}>
                    <option disabled key='0'>Select topic</option>
                    {categories.filter(
                        (opt: any) => opt.topic_type === 't'
                    ).map((opt: any) => (
                        <option key={opt.id} id={opt.id} value={opt.name}>{opt.name}</option>
                    ))}
                </select>        
                <button className="editable" onClick={save}>Save</button>
            </div>
        )
    }

    return isEditing ? <CategoryEditing /> : <CategoryStatic />
}
