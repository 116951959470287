import { Suspense } from 'react';
import { Await, Link, useLoaderData } from 'react-router-dom';
import URLS from 'urls';


export default function VerifyEmail() {
    const { verificationPromise } = useLoaderData() as { verificationPromise: {} };

    return (
        <>
            <Suspense fallback={<Placeholder />}>
                <Await resolve={verificationPromise}>
                    {(user) =>
                        user.is_email_verified ? <VerifiedEmail /> : <UnverifiedEmail />
                    }
                </Await>
            </Suspense>

        </>
    );
};

function VerifiedEmail() {
    return (
        <>
            <h3>Email verified</h3>
            <p>You've successfully verified your email address.  <Link to={URLS('home')} className="underline red">Let's start reading</Link>.</p>
        </>        
    )    
}

function UnverifiedEmail() {
    return (
        <>
            <div className="banner">
                <div className="error">Error verifying your email address.</div>
            </div>          
            <h3>Email verification failed</h3>
            <p>We were unable to verify your email address. This usually happens because you clicked on an invalid link.</p>
        </>        
    )    
}

function Placeholder() {
    return (
        <div className="login-loader">
            <div className="lds-ripple"><div></div><div></div></div>
        </div>    
    )    
}