import { Suspense } from 'react';
import { Await, useLoaderData, useOutletContext } from 'react-router-dom';
import { MultilinePlaceholder } from 'components/common';
import { useLoginRedirect } from 'utils/hooks';
import EditEmail from 'pages/user/EditEmail';
import 'css/settings.scss';
import PaymentSettings from 'pages/user/PaymentSettings';

/**
 * Render the accounts page in settings
 */
export default function Account() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const { statsPromise } = useLoaderData() as {statsPromise: StringDictType};

    useLoginRedirect(userPromise);

    return (
        <div className="settings">
            <h2>Account</h2>

            <h5>Email address</h5>
            <EditEmail />

            <h5>Reading stats</h5>
            <Suspense fallback={<MultilinePlaceholder numLines={2} />}>
                <Await resolve={statsPromise}>
                    {(stats) => (
                        <>
                        <div className="pure-g">
                            <div className="pure-u-4-5">Your top category</div>
                            <div className="pure-u-1-5 right">{stats.top_category}</div>
                        </div>
                        <div className="pure-g">
                            <div className="pure-u-4-5">Average article length</div>
                            <div className="pure-u-1-5 right">{stats.avg_read_time}</div>
                        </div>
                        </>
                    )}
                </Await>
            </Suspense>

            <h5>Subscription</h5>
            <Suspense fallback={<MultilinePlaceholder numLines={3} />}>
                <Await resolve={userPromise}>
                    {(user) => <PaymentSettings user={user} />}
                </Await>
            </Suspense>

        </div>
    )
}
