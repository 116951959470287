import {
    Await,
    Link,
    useOutletContext,
} from 'react-router-dom';
import { Component, Suspense } from 'react';
import { showPaywall, showPostPaywall } from 'utils/permissions';
import { PostPreviewSnippet } from 'pages/post/PostPreview';
import { getSubscribeUrl } from 'urls';
import { ellipsify } from 'utils/uihelpers';
import { PostPostType } from 'pages/post/Post';


/**
 * The body of the post
 * Wrap the content of the post to apply formatting and handle previews
 */
export default class PostContent extends Component<{
    post: PostType,
    isPreview?: boolean,
}> {

    postPreviewId = 'post-content-preview';
    postFullId = 'post-content-full';

    componentDidMount() {
        // Remove the preview that was rendered prior
        const postPreview = document.getElementById(this.postPreviewId) as HTMLDivElement;
        const postFull = document.getElementById(this.postFullId) as HTMLDivElement;

        if (postFull && postPreview) {
            postPreview.remove();
        }

        // Format body
        // Needs to be on delay to solve a mysterious bug where on page refresh when the post
        // loads instantly from cache, the items don't get updated
        setTimeout(() => {
            this._addFormatting();

            // Add image enlargement functionality
            if(!this.props.isPreview) {
                this._addImageEnlargement();
            }

        }, 200);           
    }
  
    componentDidUpdate() {
        this._addFormatting();
    }

    // Render full content or preview
    render() {
        return (
            <div className={`${this.props.post.post_type === PostPostType.short && 'short-post'}`}>
                {this.props.isPreview ? this._previewContent() : this._fullContent()}
            </div>
        )
    }

    // Helpers

    // Add formatting - twitter and code formatting
    _addFormatting() {
        // Update code syntax highlighting
        // @ts-ignore
        const hljs = window.hljs;
        if(hljs) {
            document.querySelectorAll("pre code").forEach(block => {
                hljs.highlightBlock(block);
            });
        }
        // Render tweets
        // @ts-ignore
        const twttr = window.twttr;
        if(twttr) {
            twttr.widgets.load();
        }
    };

    // Add funcionality to enlarge an image when clicking on it
    _addImageEnlargement() {
        const enlargeableImages = document.querySelectorAll('div.post-content img:not(.no-enlarge)');

        // Add overlay as first child of the body
        if(document.querySelector('div.image-overlay') === null) {
            const overlay = document.createElement('div');
            overlay.classList.add('image-overlay');
            document.body.insertBefore(overlay, document.body.firstChild);    
        }

        // Get overlay that goes behind the image
        const overlay = document.querySelector('div.image-overlay')!;

        // Add click event listener on every image
        enlargeableImages.forEach((image) => {
            function enlargementHandler() {
                image.classList.toggle('enlarged');
                overlay.classList.toggle('image-overlay-visible');
            };            
            // Only add listener once
            const hasAddedListner = image.classList.contains('enlargeable');
            image.classList.add('enlargeable');
            if(!hasAddedListner) {
                image.addEventListener('click', enlargementHandler);
            }
        });   

        // Remove the enlargement on scroll
        window.addEventListener('wheel', () => {
            enlargeableImages.forEach((image) => {
                overlay.classList.remove('image-overlay-visible');
                image.classList.remove('enlarged');
            });
        });

        // Remove the enlargement on escape
        document.addEventListener('keydown', function(event) {
            if (event.key === 'Escape') {
                enlargeableImages.forEach((image) => {
                    overlay.classList.remove('image-overlay-visible');
                    image.classList.remove('enlarged');
                })
            }
        });           
          
    }

    // Render the truncating content with preview shimmer animation below it
    _previewContent() {
        // Render preview content + placeholder
        return (
            <div id={this.postPreviewId}>
                <ContentContainer post={this.props.post} />
                <div className="post-content">
                    <div className="placeholder-content-shimmer">
                        <PostPreviewSnippet />
                    </div>
                </div>
            </div>
        )
    }

    _fullContent() {
        return (
            <div id={this.postFullId}>
                <ContentContainer post={this.props.post} />
            </div>
        )
    }

};

function ContentContainer({ post }: { post: PostType }) {
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    return (
        <Suspense fallback="">
            <Await resolve={userPromise}>
            {(user: UserType) =>
            <>
                <div
                    // Show gradient for logged out users only, to drive getting their email address via paywall
                    // className={`post-content ${(showPostPaywall(user, post) && !user.id && post.post_type === PostPostType.full) && 'gradient-text-overlay'}`}
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.content! }}
                />
                {/* Note: check general paywall and not post paywall bc we always show the
                subscription prompt if the user is not subscribed, even if the post is public. */}
                {showPaywall(user) && <SubscriptionPrompt user={user} post={post}/>}
            </>
            }
            </Await>
        </Suspense>
    );       
}


/**
 * Gray box to show after the faded out paywalled article content.
 */
export function SubscriptionPrompt({ user, post }: { user: UserType, post?: PostType }) {
      
    return (
        <div className="subscription-prompt">
            <Link to={getSubscribeUrl(user)}>
                <h3>Continue reading</h3>
                {post?.post_type === PostPostType.full ?
                <p>This post is for paid subscribers. Subscribe to read the full article.</p>
                : <p>Subscribe to read more posts like this.</p>}
                <button className="btn-primary">Subscribe</button>
            </Link>
        </div>
    )
}
