import { lazy, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Settings, { helpCenterUrl } from 'utils/settings';
import { showBanner } from 'components/Banner';
import URLS from 'urls';
import { createSubscription } from 'data/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import API from 'utils/api';
import AppStorage from 'utils/appstorage';

const LinkAuthenticationElement = lazy(
    () => import('@stripe/react-stripe-js').then(
        module => ({ default: module.LinkAuthenticationElement })
    )
);
const PaymentElement = lazy(
    () => import('@stripe/react-stripe-js').then(
        module => ({ default: module.PaymentElement })
    )
);

/**
Create user and take payment.
Stripe checkout form:
- Email
- Link
- Card input
- Foundation subscription disclosures
- Submit button
 */
export default function StripeCheckout({ user, price }: { user: UserType, price: number }) {

    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();

    const navigate = useNavigate();

    // Form loading states
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState('');

    // Set email from logged in user or from email saved on paywall
    const [email, setEmail] = useState(user.email);

    // Redirect url based on the checkout outcome
    function getRedirectUrl(scenario: string): string {
        const baseSuccess = Settings.REACT_APP_BASE_URL + URLS('subscribeSuccess');
        const redirectUrls: StringDictType = {
            // Subscription is already current and user will not be charged.
            subscriptionActive: URLS('subscribeSuccess') + '?existing_payment=true',
            // Subscription created for a new user (most common scenario)
            newSubscription: baseSuccess,
        }
        return redirectUrls[scenario]
    }

    // First month discount
    const appStorage = new AppStorage();
    const first_month_price = appStorage.get(appStorage.keys.first_month_price);

    /*
    Take payment
    - Stripe form validation and wallet collection
    - Create (or get) Stripe user
    - Create Stripe subscription
    - Charge user
    - Outcome:
    - Success: Redirect to success page
    - Error: show error state    
    */
    async function handleSubmit(e: Event) {
        e.preventDefault();

        if (elements == null) {
            return;
        }

        // Strip form validation
        const {error: submitError}: DictType = await elements.submit();
        if (submitError) {
            // To access first error, use submitError.message
            return;
        }

        // Show blocking loader
        setInProgress(true);

        // Handle updating email address
        if(email !== user.email) {
            const response = await new API().updateUser({'email': email});
            // Error updating email
            if(response.message) {
                showBanner(response.message);
                setError(response.message);
                setInProgress(false);
                return;
            }    
        }

        // Handle generic error for the entire user and subscription creation,
        // so everything fails gracefully
        try {

            // Create the Stripe customer and subscription
            const {
                created: subscriptionCreated,
                client_secret: clientSecret
            } = await createSubscription();    

            // If the subscription was not created (bc its still active), do not take payment
            if (!subscriptionCreated) {
                setInProgress(false);
                navigate(getRedirectUrl('subscriptionActive'));
                return
            }

            // Submit payment
            const {error} = await stripe!.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: getRedirectUrl('newSubscription')
                },
            });

            // Immediate error confirming payment
            // Show error to your customer (for example, payment details incomplete)
            if (error && error.message) {
                showBanner('Error subscribing. ' + error.message);
                setError(error.message);
                setInProgress(false);
                return;
            }

        // Catch unknown error
        } catch {
            const msg = 'Unknown error subscribing.';
            showBanner(msg);
            setError(msg);
        }

        setInProgress(false);
    };

    // Focus the email input box or the 6 digit code once the element has finished loading
    function handleLinkOnReady() {
        const linkElement = elements!.getElement('linkAuthentication')!;
        linkElement.focus();    
    }

    return (
        <form onSubmit={(e: any) => handleSubmit(e)} className="checkout">

            <LinkAuthenticationElement 
                onReady={handleLinkOnReady}
                options={{
                    defaultValues: {
                        email: email,
                    },
                }}
                onChange={(event) => {
                    // Update email var so we can update the user's email
                    setEmail(event.value.email);
                }}                 
            />

            <br />
            <PaymentElement />

            {first_month_price ?
            // First month discount
            <>
                <div className="pure-g total-price with-discount first-month">
                    <div className="pure-u-3-5"><h5>Today's total</h5></div>
                    <div className="pure-u-2-5"><h5>${(first_month_price / 100).toFixed(2)}</h5></div>
                </div>
                <div className="pure-g total-price with-discount ongoing">
                    <div className="pure-u-4-5"><span>Due in 30 days (cancel anytime)</span></div>
                    <div className="pure-u-1-5"><span>${price / 100}</span></div>
                </div>
            </>
            // Annual/monthly pricing (no discount)
            :
            <div className="pure-g total-price">
                <div className="pure-u-3-5"><h5>Today's total</h5></div>
                <div className="pure-u-2-5"><h5>${price / 100}</h5></div>
            </div>}

            <span className="disclosure">
                I agree to the <Link to={helpCenterUrl('paymentPolicy')} target="_blank">Payment Policy</Link>. Subscription auto-renews <Link to={helpCenterUrl('cancelSubscription')} target="_blank">until canceled</Link>. No refunds. Prices subject to change. Cancel anytime.
            </span>

            {/* Error during submission */}
            {error && <span className="btn-primary-error-above">{error}</span>}

            <button className="btn-primary" type="submit" disabled={!stripe || !elements || inProgress}>
                Subscribe
                {inProgress &&
                 <FontAwesomeIcon icon={faCircleNotch} className="spinner fa-spin fast-spin" />}
            </button>            
        </form>
  );
};
