import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Settings from 'utils/settings';
import { showBanner } from 'components/Banner';
import 'css/menus.scss';


/**
 * Share menu when the native mobile share is not available.
 */
export default function WebShareMenu({ post }: { post: Partial<PostType> }) {

    function canShare() {
        if( !post.is_published ) {
            showBanner('Cannot share an unpublished post.');    
            return false;
        }
        return true;
    }

    function copyToClipboard() {
        if(!canShare()) { return }

        navigator.clipboard.writeText(window.location.href);
        showBanner('Link copied to clipboard.');
    }

    function shareLinkedin() {
        if(!canShare()) { return }

        const linkedin = (
            "https://www.linkedin.com/sharing/share-offsite/?url="
            + window.location.href
        )    
        window.open(linkedin, 'Foundation', 'width=500,height=500');
    }

    function shareTwitter() {
        if(!canShare()) { return }

        const twitter = (
            "https://twitter.com/intent/tweet?text="
            + post.title + " "
            + window.location.href
            + " via "
            + Settings.TWITTER_HANDLE
        );
        window.open(twitter, "_blank");
    }

    return (
        <div className="popover-menu">
            <button onClick={copyToClipboard}>
                <FontAwesomeIcon icon={faLink} /><span>Copy link</span>
            </button>
            <hr />
            <button onClick={shareTwitter}>
                <FontAwesomeIcon icon={faTwitter} /><span>Twitter</span>
            </button>
            <button onClick={shareLinkedin}>
                <FontAwesomeIcon icon={faLinkedin} /><span>Linkedin</span>
            </button>
        </div>
    )
}
