import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

// Global ID to retreive the hidden banner DOM
const bannerID = 'global-banner';

/**
 * Fixed banner below the nav that can be filled by for feedback to the user
 */
export default function Banner() {
    const bannerRef: React.RefObject<HTMLDivElement> = useRef(null);

    function close() {
        bannerRef.current!.classList.remove('show');
        const bannerContent = bannerRef.current!.querySelector('.banner-content')!;
        bannerContent.innerHTML = '';
    }

    return (
        <div id={bannerID} className="top-banner" ref={bannerRef}>
            <span className="banner-content"></span>
            <span className="close" onClick={close}>
                <FontAwesomeIcon icon={faXmark} />
            </span>
        </div>
    )
}

/**
 * Public function to interact with the banner
 */
export function showBanner(message: string, fadeOut: boolean = true) {
    const banner: HTMLElement = document.getElementById(bannerID)!;
    const bannerContent: HTMLElement = banner.querySelector('.banner-content')!;

    // Set message
    bannerContent.innerHTML = message;

    // Show
    banner.classList.add('show');

    // Fade out automatically after 3 seconds
    if (fadeOut) {
        setTimeout(() => {
            banner.classList.remove('show');
            bannerContent.innerHTML = '';
        }, 3000);    
    }

}