import { Link } from 'react-router-dom';
import URLS from 'urls';
import Settings, { helpCenterUrl } from 'utils/settings';


export default function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <Link to={URLS('about')}>About</Link> ·&nbsp;
                <Link to={helpCenterUrl('terms')} target="_blank">Terms</Link> ·&nbsp;
                <Link to={helpCenterUrl('privacy')} target="_blank">Privacy</Link> ·&nbsp;
                <Link to={helpCenterUrl('home')} target="_blank" >Support</Link>
                <div className="company">© {new Date().getFullYear()} {Settings.COMPANY_LEGAL_NAME}</div>
            </div>
        </footer>         
    )    
}