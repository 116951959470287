import AppStorage from 'utils/appstorage';
import Settings from 'utils/settings';

/**
 * Manage caching categories displayed in the navbar
 */
export class CategoriesCache {

    // Minutes before we consider the post stale
    minutesStale = Settings.MINUTES_CACHE_STALE;

    key = 'categories'
    storage: AppStorage

    constructor() {
        this.storage = new AppStorage();
    }

    // Set on local storage
    set(categoriesPromise: DictType) {
        // Fetch post from backend if promise not passed
        if(categoriesPromise) {
            categoriesPromise.then((categories: DictType) => {
                this._set(categories);
            });
        }
    }

    // Get categories from local storage
    get() {
        const item = this.storage.get(this.key);
        const staleTime = this.minutesStale * 60 * 1000;
        if (item && Date.now() - item.updated_at < staleTime) {
            // Make sure categories are a list and not an error from the
            // server that was cached accidentally (can happen if you're token becomes
            // invalid and you try to retreive categories with an invalid token)
            if (!Array.isArray(item.data)) {
                return null
            }
            return item.data
        }
    }

    delete() {
        this.storage.delete(this.key);
    }

    // Set on local storage
    _set(item: DictType) {
        const toCache = {
            data: item,
            updated_at: Date.now(),
        }
        this.storage.set(this.key, toCache);
    }

}