import { MouseEventHandler, Suspense, lazy, useEffect, useState } from 'react';
import {
    Await,
    useLoaderData,
} from 'react-router-dom';
import API from 'utils/api';
import { PostCache } from 'storage/postcache';
import { setAllTabIndexesNegative } from 'utils/uihelpers';
import { updateDictDeepCopy } from 'utils/utils';
import { usePostMetaData } from 'utils/hooks';
import { Title, Subtitle, ByLine, PostPostType, ImageCaption, Warnings } from 'pages/post/Post';
import PostPreview from 'pages/post/PostPreview';
import NoPage from 'pages/base/NoPage';
import PostToolbar from 'pages/post/PostToolbar';
import ImageUpload from 'pages/post/editor/PostImageUpload';
import EditableCategory from 'pages/post/editor/EditableCategory';
import HTMLEditor from 'pages/post/editor/HTMLEditor';
import { cleantSVGs } from 'pages/post/editor/Editor';
import 'css/post.scss';
import 'css/editpost.scss';
import { showBanner } from 'components/Banner';
const ContentEditor = lazy(() => import('pages/post/editor/Editor'));


export default function EditPost() {

    // ** Constants **

    // Manage debouncing for post saving
    let postSaveDebouncerTimer: NodeJS.Timeout;

    // ** Hooks **

    const { postPromise, categoriesPromise } = useLoaderData() as {
        postPromise: PostType,
        categoriesPromise: Array<PostTopicType>;
    };

    // Manage post via element state
    const [post, setPost] = useState<Partial<PostType>>({});

    // Editor is either tiny (rich text wysiwyg) or html (raw html)
    const [isEditorRichText, setIsEditorRichText] = useState(true);

    // Set page title and metadata dynamically
    usePostMetaData(postPromise)

    // Focus on the title when page loads
    useEffect(() => {
        // Set tabIndex to -1 for all elements for which tabIndex is not
        // explicitly set. This allows for natural tab movement when editing.
        // We set tabIndex to: Title: 1, Subtitle: 2, Editor: 3
        setAllTabIndexesNegative()

        // Initialize post state
        if(postPromise) {
            (postPromise as any).then((post: PostType) => {
                setPost(post)
            });    
        }        
    }, []);

    // ** UI **

    function toggleEditor() {
        setIsEditorRichText(!isEditorRichText);
    }

    // ** Data **

    function updateTitle(e: Event) {
        const title = (e.target as HTMLElement).innerText;
        // Update state so that slugifying the title works
        post.title = title;
        setPost(post);
        // Save
        savePost('title', title);
    }

    function updateImageCaption(e: Event) {
        savePost('image_caption', (e.target as HTMLElement).innerText);
    }

    function updateSubtitle(e: Event) {
        savePost('subtitle', (e.target as HTMLElement).innerText);
    }

    function updatePostField(e: Event) {
        // JS note: currentTarget refers to the element to which the event handler
        // is attached (in this case, the button), while the target property refers
        // to the actual element that triggered the event (which can be the span
        // if it was clicked).
        const element = (e.currentTarget as HTMLElement);
        const field = element.getAttribute('name')!;

        // If passed a value use it, otherwise assume the value is a boolean field and flip it
        const value = element.getAttribute('value') || !post[field];

        // Update state
        const updatedPost = updateDictDeepCopy(post, field, value);
        setPost(updatedPost);

        // Save
        savePost(field, value);
    }

    function editorChange(content: string) {
        // Clean post content before saving
        content = cleantSVGs(content);
        content = content.replace('<head>', '').replace('</head>', '');
        content = content.replace('<body>', '').replace('</body>', '');        

        // Set links to external if a current event
        if (post.post_type === PostPostType.short) {
            const elements = document.querySelectorAll('div.mce-content-body a');
            elements.forEach((element) => {
                const elem = element as HTMLElement;
                elem.setAttribute('target', '_blank');
            });                    
        }

        // Update state (shared between tiny and html editors)
        post.content = content;
        setPost(post);
        // Save
        savePost('content', content);
    }

    function savePost(key: string, value: any) {
        // Clear the cache when we're updating a post, or the
        // preview will be behind by the post being edited
        new PostCache().delete(post.slug!);

        // Debounced update (e.g. for typing)
        // Debounce to only call the API 0.3 seconds after done typing
        // This works by delaying execution by 0.3 seconds, and if a new
        // call arrives before then, it resets with clearTimeout
        clearTimeout(postSaveDebouncerTimer);
        postSaveDebouncerTimer = setTimeout(async () => {
            const data: Partial<PostType> = {id: post.id}
            data[key] = value;
            try {
                await new API().updatePost(data);
            } catch {
                showBanner('Error saving post.');
            }
            
        }, 300);    
    }

    // ** Render **

    return (
        <div className="post edit-post">
            <Suspense fallback={<PostPreview />}>
                <Await resolve={postPromise}>
                    {/* Use post from state set in use effect. Only using suspense/await for the ui. */}
                    {(_) =>
                        // Has edit permissions
                        post.can_edit ?
                        <>
                        <Warnings post={post} />
                        {post.post_type !== PostPostType.static &&
                        <Await resolve={categoriesPromise}>
                            {(categories) => <EditableCategory categories={categories} post={post}/>}
                        </Await>}
                        <Title
                            title={post.title!}
                            isEditable={true}
                            onInput={(e: Event) => updateTitle(e)}
                        />
                        <PostToolbar
                            post={post}
                            showTools={post.can_edit}
                            isEditing={true} 
                            onUpdatePostField={(e: any) => updatePostField(e)}
                        />

                        {/* Image, subtitle, author only on long posts */}
                        {post.post_type === PostPostType.full
                        ?
                        <>
                            <ImageUpload className="hero-img" imageUrl={post.image!} post={post}/>
                            <ImageCaption
                                caption={post.image_caption!}
                                isEditable={true}
                                onInput={(e: Event) => updateImageCaption(e)}
                            />
                            <Subtitle
                                subtitle={post.subtitle!}
                                isEditable={true}
                                onInput={(e: Event) => updateSubtitle(e)}
                            />

                            <div className="pure-g">
                                {/* Author byline */}
                                <div className="pure-u-4-5">
                                    <ByLine post={post} />
                                </div>
                                {/* Toggle between Tiny MCE editor and html editor */}
                                <div className="pure-u-1-5">
                                    <EditorToggle isEditorRichText={isEditorRichText} toggleEditor={toggleEditor} />
                                </div>
                            </div>                            

                        </>
                        // Show the editor toggle on static and current event posts
                        :
                        <div className="pure-g">
                            <EditorToggle isEditorRichText={isEditorRichText} toggleEditor={toggleEditor} />
                        </div>
                        }

                        {/* Tiny MCE editor or raw html editor */}
                        <div className={`${post.post_type === PostPostType.short && 'short-post'}`}>
                            {isEditorRichText ?
                            <ContentEditor post={post} updatePostAction={editorChange}/>
                            : <HTMLEditor post={post} updatePostAction={editorChange}/>}
                        </div>
                        </>

                        // User is not an editor, 404 
                        : <NoPage />
                    }
                </Await>
            </Suspense>
        </div>
    )
}

function EditorToggle({ isEditorRichText, toggleEditor } : {
    isEditorRichText: boolean,
    toggleEditor: MouseEventHandler<HTMLButtonElement>,
}) {
    return (
        <div className="editor-toggle">
            <button
                className={`${isEditorRichText && 'selected'}`}
                disabled={isEditorRichText}
                onClick={toggleEditor}>
                    Full editor
            </button>
            &nbsp;·&nbsp;
            <button
                className={`${!isEditorRichText && 'selected'}`}
                disabled={!isEditorRichText}
                onClick={toggleEditor}>
                    Raw html
            </button>            
        </div>        
    )
}