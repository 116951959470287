/**
 * Global settings across the whole app
 */
export default class Settings {

    // App behavior
    static MINUTES_CACHE_STALE = 10;

    // API Keys
    static GOOGLE_ANALYTICS_GA4_TOKEN = process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_TOKEN;
    static LINKEDIN_PARTNER_ID = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
    static TWITTER_CONVERSION_ID = process.env.REACT_APP_TWITTER_CONVERSION_ID;
    static STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    
    // Static content
    static API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    static REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    static COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
    static COMPANY_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
    static COMPANY_LEGAL_NAME = process.env.REACT_APP_COMPANY_LEGAL_NAME;
    static TWITTER_HANDLE = process.env.REACT_APP_TWITTER_HANDLE;
    static ENV = process.env.REACT_APP_ENV;
    static HELP_CENTER_LINKS: StringDictType = {
        home: 'https://help.foundation.news/hc/en-us/',
        cancelSubscription: 'articles/16082169854491',
        paymentPolicy: 'articles/16081843124507',
        terms: 'articles/16081690768795',
        privacy: 'articles/16081821953563',
    }
    static ABOUT_URL = Settings.REACT_APP_BASE_URL + '/post/about';

    // Dynamically get static assets from the static folder in collect static
    // if in the cloud; otherwise serve locally from the npm server.
    static GET_REACT_APP_STATIC_URL = () => {
        return Settings.ENV === 'LOCAL' ? '/' : process.env.REACT_APP_STATIC_URL;
    }

    
    // Environment
    static isProd = () => {
        return Settings.ENV === 'PROD';
    }

}


export function helpCenterUrl(page: string) {
    let url: string = Settings.HELP_CENTER_LINKS.home;
    if( page !== 'home') {
        url += Settings.HELP_CENTER_LINKS[page];
    }
    return url;
}