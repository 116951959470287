import { defer } from 'react-router-dom';
import API from 'utils/api';
import AppStorage from 'utils/appstorage';


/*****************************************************************************
 * Loaders
 *****************************************************************************/

// Base layout loader. Load user for the whole site.
export async function userLoader() {
    const userPromise = new API().getUser();
    return defer({ userPromise });
};

// VerifyEmail page loader. Attempt to verify email.
export async function verifyEmailLoader({ params }: DictType) {
    const verificationPromise = new API().verifyEmail(params.useridHash);
    return defer({ verificationPromise });
};

// Pricing page loader
export async function subscriptionPricingLoader() {
    const pricingPromise = getSubscriptionPricing();
    return defer({ pricingPromise });
};

// Unsubscribe page loader
export async function unsubscribeLoader({ params }: DictType ) {
    const unsubPromise = new API().unsubscribeNotifications(params.emailType, params.useridHash);
    return defer({ unsubPromise });
};


/*****************************************************************************
 * Subscription helpers
 *****************************************************************************/

// Set up a new subscription from a newly created user
export async function createSubscription() {

    // Pass info from the last screen
    const appStorage = new AppStorage();
    const pricingKey = appStorage.get(appStorage.keys.subscription_pricing_key);
    const isAnnual = appStorage.get(appStorage.keys.subscription_is_annual);
    const price = appStorage.get(appStorage.keys.subscription_price);

    if(!pricingKey || isAnnual === null || !price) {
        throw Error('Cannot create subscription because missing info.');
    }

    // Create subscription (blocking)
    const data: StringDictType = {
        pricing_key: pricingKey,
        is_annual: isAnnual,
        price: price
    }
    return await new API().createSubscription(data)
}

// Get the subscription pricing options. If the user has already
// seen a pricing scheme, keep it sticky
async function getSubscriptionPricing() {
    const appStorage = new AppStorage();
    const pricingKey = appStorage.get(appStorage.keys.subscription_pricing_key);
    return new API().getSubscriptionPricing(pricingKey)
}
