import 'css/loaders.scss';

// Global ID to retreive the hidden loader DOM
const loaderID = 'global-loader';

/**
 * Fixed loader center on the screen
 */
export default function Loader() {
    return (
        <div id={loaderID} className="global-loader">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

// Public function to fade in the loader
export function showLoader() {
    const loader: HTMLElement = document.getElementById(loaderID)!;
    loader.classList.add('show');
}

// Public function to fade out the loader
export function hideLoader() {
    const loader: HTMLElement = document.getElementById(loaderID)!;
    loader.classList.remove('show');
}
