import { Suspense, lazy, useEffect } from 'react';
import { Await, useOutletContext, useSearchParams } from 'react-router-dom';
// Use /pure lib to load Stripe async
// https://github.com/stripe/stripe-js#importing-loadstripe-without-side-effects
import { loadStripe } from '@stripe/stripe-js/pure';
import Settings from 'utils/settings';
import StripeCheckout from 'pages/stripe/StripeCheckout';
import { getStripeCheckoutConfig } from 'pages/stripe/stripe_config';
import AppStorage from 'utils/appstorage';
import URLS from 'urls';
import { showBanner } from 'components/Banner';
import { useCreateUserRedirect } from 'utils/hooks';
import API from 'utils/api';
import { signupLanguage } from 'language/signup_language';
import 'css/subscription.scss';

const Elements = lazy(
    () => import('@stripe/react-stripe-js').then(
        module => ({ default: module.Elements })
    )
);

// Async load stripe
async function getStripe() {
    return await loadStripe(Settings.STRIPE_PUBLIC_KEY!);
}

// Wrapper for creating a user and taking payment. 
export default function Subscribe() {

    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const [searchParams] = useSearchParams();

    // Redirect to create user if user is not logged in
    useCreateUserRedirect(userPromise);

    useEffect(() => {
        const paymentFailed = searchParams.get('payment') === 'failed';
        if(paymentFailed) {
            showBanner('Payment failed. Please try again.')
        }
    }, [userPromise, searchParams]);

    // Get price that was saved on /pricing. If doesn't exist, redirect back to pricing.
    const appStorage = new AppStorage();
    const price = appStorage.get(appStorage.keys.subscription_price);
    if (!price) {
        window.location.href = URLS('pricing');
    }

    // Track page view
    new API().trackPage('Subscribe');

    /*
    Config docs:
    ------------
    - Docs for collecting payment before creating the customer:
      https://stripe.com/docs/payments/accept-a-payment-deferred?type=subscription
    - Amount: reflect what a customer will be charged immediately. First installment of the
      subscription
    - Units: smallest currency unit (e.g., 100 cents to charge $1.00 or 100 to
      charge ¥100, a zero-decimal currency)
    */
    const stripeCheckoutConfig = getStripeCheckoutConfig(price);

    return (
        <div className="desktop-utilities-content pricing">
            <div className="header">
                <h2 className="header">Subscribe</h2>
                <p>{signupLanguage.description}</p>
            </div>

            <div className="body">
                <Suspense>
                    <Await resolve={userPromise}>
                        {(user) => 
                        <Elements stripe={getStripe()} options={stripeCheckoutConfig}>
                            <StripeCheckout user={user} price={price} />
                        </Elements>
                        }
                    </Await>
                </Suspense>                      
            </div>

        </div>
    )
}
