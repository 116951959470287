// React
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
// Pages
import { LayoutBase, LayoutContent } from 'pages/base/Layout';
import Home from 'pages/home/Home';
import Post from 'pages/post/Post';
// import CurrentEventsHome from 'pages/home/CurrentEventsHome';
import EditPost from 'pages/post/editor/EditPost';
import RootBoundary from 'pages/base/RootBoundary';
import CreateUser from 'pages/subscription/CreateUser';
import Subscribe from 'pages/subscription/Subscribe';
import SubscribeSuccess from 'pages/subscription/SubscribeSuccess';
import Login, { loginForm } from 'pages/auth/Login';
import LoginConfirm from 'pages/auth/LoginConfirm';
import LoginConfirmExistingUser from 'pages/auth/LoginConfirmExistingUser';
import Pricing from 'pages/subscription/Pricing';
import LoginRedirect, { loginRedirectForm } from 'pages/auth/LoginRedirect';
import Bookmarks from 'pages/postlists/Bookmarks';
import Unsubscribe from 'pages/user/Unsubscribe';
import VerifyEmail from 'pages/user/VerifyEmail';
import DraftPosts from 'pages/postlists/Drafts';
import Account from 'pages/user/Account';
import NoPage from 'pages/base/NoPage';
import Settings from 'pages/user/Settings';
import SubscriptionSettings from 'pages/user/SubscriptionSettings';
import CloseAccount from 'pages/user/CloseAccount';
import CancelSubscription from 'pages/user/CancelSubscription';
// Utils
import URLS from 'urls';
// Data
import {
    userLoader,
    subscriptionPricingLoader,
    unsubscribeLoader,
    verifyEmailLoader
} from 'data/user';
import {
    categoriesLoader,
    postsLoader,
    postLoader,
    editPostLoader,
    newPostLoader,
    draftsLoader,
} from 'data/posts';
import {
    accountLoader,
    settingsLoader,
    subscriptionSettingsLoader
} from 'data/account';
import { bookmarksLoader } from 'data/bookmarks';
// import { currentEventsLoader } from 'data/currentevents';
// CSS
import 'libraries/pure-min.css';
import 'libraries/grids-responsive-min.css';
import 'css/base.scss';
import 'css/navbar.scss';
import 'css/footer.scss';
import 'css/loaders.scss';
import 'css/modals.scss';
import 'css/paywalls.scss';
import 'css/components.scss';


// Routes for the entire site
const routes = createRoutesFromElements(
<Route errorElement={<RootBoundary />}>
    {/* Main site */}
    <Route element={<LayoutBase />} loader={userLoader}>
        <Route element={<LayoutContent isHome={true} key='home' />} loader={categoriesLoader}>
            {/* Home */}
            <Route index loader={postsLoader} element={<Home />} />
            <Route path={URLS('homePreview')} loader={postsLoader} element={<Home />} />
            <Route path={URLS('homeCategory')} loader={postsLoader} element={<Home />} />
            <Route path={URLS('homeCategoryPreview')} loader={postsLoader} element={<Home />} />
        </Route>
        <Route element={<LayoutContent />} key='main' >
            {/* User */}
            <Route path={URLS('loginRedirect')} element={<LoginRedirect />} action={loginRedirectForm}/>
            <Route path={URLS('login')} element={<Login />} action={loginForm}/>
            <Route path={URLS('loginConfirm')} element={<LoginConfirm />} />
            <Route path={URLS('loginConfirmExistingUser')} element={<LoginConfirmExistingUser />} />
            <Route path={URLS('createUser')} element={<CreateUser />} />
            <Route path={URLS('pricing')} loader={subscriptionPricingLoader} element={<Pricing />} />
            <Route path={URLS('subscribe')} element={<Subscribe />} />
            <Route path={URLS('unsubscribe')} loader={unsubscribeLoader} element={<Unsubscribe />} />
            {/* Settings */}
            <Route path={URLS('account')} loader={accountLoader} element={<Account />} />
            <Route path={URLS('settings')} loader={settingsLoader} element={<Settings />} />
            <Route path={URLS('subscriptionSettings')} loader={subscriptionSettingsLoader} element={<SubscriptionSettings />} />
            <Route path={URLS('subscriptionSettingsCancel')} element={<CancelSubscription />} />
            <Route path={URLS('accountCancel')} element={<CloseAccount />} />
            {/* Posts */}
            <Route path={URLS('post')} loader={postLoader} element={<Post />}/>
            <Route path={URLS('postPreview')} loader={postLoader} element={<Post />}/>
            <Route path={URLS('postVersion')} loader={postLoader} element={<Post />}/>
            <Route path={URLS('postEdit')} loader={editPostLoader} element={<EditPost />}/>
            <Route path={URLS('postNew')} loader={newPostLoader} element={<EditPost />}/>
            <Route path={URLS('postBookmarks')} loader={bookmarksLoader} element={<Bookmarks />}/>
            {/* <Route path={URLS('currentEvents')} loader={currentEventsLoader} element={<CurrentEventsHome />}/> */}
            {/* <Route path={URLS('currentEventsPreview')} loader={currentEventsLoader} element={<CurrentEventsHome />}/> */}
            <Route path={URLS('postDrafts')} loader={draftsLoader} element={<DraftPosts />}/>
            {/* Other */}
            <Route path={URLS('404')} element={<NoPage />}/>
        </Route>
        {/* </Route> */}
    </Route>
    {/* Force reload user by separate the routes. Reload user bc we need to refetch after account creation. */}
    <Route element={<LayoutBase />} loader={userLoader}>
        <Route element={<LayoutContent />} key='auth' >
            <Route path={URLS('subscribeSuccess')} element={<SubscribeSuccess />} />
            <Route path={URLS('verifyEmail')} loader={verifyEmailLoader} element={<VerifyEmail />} />
        </Route>
    </Route>
</Route>
)

const router = createBrowserRouter(routes);

export default function App() {
    return <RouterProvider router={router} />;
};