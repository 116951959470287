import { MouseEventHandler, Suspense } from 'react';
import { Await, Link, useLoaderData, useNavigate } from 'react-router-dom';
import API from 'utils/api';
import URLS, { getPostUrl } from 'urls';
import { slugify } from 'utils/uihelpers';
import { PostPostType } from 'pages/post/Post';
import showModal from 'components/Modal';
import { DateFormat } from 'components/common';

/**
 * Menu with publisher tools to edit, preview publish, unpublish,
 * archive, set post slug
 */
export function PublisherTools({ post, isEditing, onUpdatePostField }: {
    post: Partial<PostType>,
    isEditing: boolean,
    onUpdatePostField: MouseEventHandler<HTMLButtonElement>,
}) {

    const navigate = useNavigate();


    // Confirm modal before resetting the slug
    function resetSlug() {
        showModal({
            title: "Are you sure?",
            body: "This will update the url of this post, which will break all old links. This cannot be undone.",
            callBack: _resetSlug,
        });

        // Reset the post's slug based on the current title.
        async function _resetSlug() {
            const newSlug = slugify(post.title!);

            const postPage = (post.is_published && post.is_active) ? 'post' : 'postPreview';
            const url = URLS(postPage, 'slug', newSlug);

            const data: Partial<PostType> = {
                id: post.id,
                slug: newSlug
            };
            await new API().updatePost(data);
            navigate(url);
        }        
    }

    // Publish the post and redirect to the post url
    async function publishPost(e: any) {
        // If post is published unpublish it and remain in edit mode
        if (post.is_published) {
            onUpdatePostField(e)
        // Publishing a post takes you out of edit mode
        } else {
            // Publish post
            const data: Partial<PostType> = {
                id: post.id,
                is_published: true
            };
            await new API().updatePost(data);
            // Redirect to post url
            navigate(URLS('post', 'slug', post.slug));    
        }
    }

    // Restore the post to this draft
    async function restoreVersion() {
        showModal({
            title: "Are you sure?",
            body: "This will restore the post to a prior version. It will also unpublish the post.",
            callBack: _restoreVersion,
        });

        async function _restoreVersion() {
            const updatePost = await new API().restorePostFromVersion(post.slug!, post.version_id!)
            navigate(getPostUrl(updatePost));
        }
    }

    // Actions to show if viewing the post in editing mode
    function EditingAcions() {
        return (
            <>
                <hr />
                {/* Set post visible or hidden on home */}
                <button name="is_visible" onClick={(e: any) => onUpdatePostField(e)}>
                    {post.is_visible
                    ? <>Hide story <span className="subtitle">Hide story from home</span></>
                    : <>Show story <span className="subtitle">Show story on home</span></>}
                </button>

                {/* Allow post to be primary */}
                <button name="can_be_primary" onClick={(e: any) => onUpdatePostField(e)}>
                    {post.can_be_primary
                    ? <>Don't allow as primary story <span className="subtitle">Prevent from being first story on home</span></>
                    : <>Allow as primary story <span className="subtitle">Allow as primary story on home</span></>}
                </button>

                {/* Make post public (i.e. not behind paywall) */}
                <button name="is_public" onClick={(e: any) => onUpdatePostField(e)}>
                    {post.is_public
                    ? <>Add paywall <span className="subtitle">Put behind the paywall (default)</span></>
                    : <>Make public <span className="subtitle">Make publicly available (no paywall)</span></>}
                </button>

                <hr />

                {/* Set post title to url */}
                <button onClick={resetSlug}>Set post URL to title</button>

                {/* Publish/archive */}
                <button name="is_published" onClick={(e: any) => publishPost(e)}>
                    {post.is_published ? <>Unpublish story</> : <>Publish story</>}
                </button>                
                <button name="is_active" onClick={(e: any) => onUpdatePostField(e)}>
                    {post.is_active ? <>Archive story</> : <>Unarchive story</>}
                </button>
            </>
        )
    }

    return (
        <div className="popover-menu">
            {/* Edit/view story */}
            <Link to={URLS('postEdit', 'slug', post.slug!)}>Edit story</Link>
            <Link to={getPostUrl(post)}>View story</Link>
            
            {/* Version view: show restore draft */}
            {Boolean(post.version_id) &&
            <button onClick={restoreVersion} className="red" >Restore this version</button>}

            {/* Publisher tools in edit mode */}
            {isEditing && <EditingAcions />}
        </div>
    )

}

/**
 * Publisher tool menu to change post type
 */
export function PostTypeEditor({ post, onUpdatePostField }: {
    post: Partial<PostType>,
    onUpdatePostField: MouseEventHandler<HTMLButtonElement>,
}) {

    return (
        <div className="popover-menu">
            <button name="post_type" value={PostPostType.full} className={`${post.post_type === PostPostType.full && "selected"}`} onClick={(e) => onUpdatePostField(e)}>
                Article
            </button>
            <button name="post_type" value={PostPostType.short} className={`${post.post_type === PostPostType.short && "selected"}`} onClick={(e) => onUpdatePostField(e)}>
                Current event
            </button>
            <button name="post_type" value={PostPostType.static} className={`${post.post_type === PostPostType.static && "selected"}`} onClick={(e) => onUpdatePostField(e)}>
                Static content
            </button>
        </div>
    )
}


/**
 * Publisher tool to view past versions
 */
export function VersionHistory({ post }: { post: Partial<PostType> }) {

    const { versionsPromise } = useLoaderData() as { versionsPromise: Array<PostVersionType> };

    return (
        <div className="popover-menu popover-menu-scrolling">
            <Suspense fallback={<button>No prior versions</button>}>
                <Await resolve={versionsPromise}>
                    {(versions) =>
                        // Has prior version
                        versions.length > 0 ?
                        versions.map((version: DictType) => (
                            <Link to={URLS('postVersion', ['slug', 'version'], [post.slug, version.id])} target="_blank" key={version.id}>
                                <DateFormat timestamp={version.updated_at} format="MMMM d, Y, h:mm:ss bbb" />
                                {version.restored_from &&
                                    <span className="subtitle">Restored</span>
                                }
                            </Link>
                        ))
                        // No version history
                        : <button>No prior versions</button>
                    }
                </Await>
            </Suspense>
        </div>

    )
}


