import { Link, Await, useLoaderData } from "react-router-dom";
import { Suspense } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUser, faBookmark, faPenToSquare, faFile, faEye } from '@fortawesome/free-regular-svg-icons';
import Tippy from '@tippyjs/react';
import URLS from 'urls';
import { helpCenterUrl } from 'utils/settings';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { logout } from 'utils/auth';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';


/**
 * Toggleable hamburger menu in the nav bar
 */
export default function HamburgerMenu() {

    return (
        <div className="tippy-home-menu"> 
        <Tippy
            trigger="click"
            placement="bottom-end"
            theme="light"
            arrow={false}
            offset={[0, 6]}
            content={<MenuContent />}
            interactive={true}
            maxWidth="568px"
            onShown={(instance) => {
                // Hide tippy on click inside
                document.querySelector('[data-tippy-root]')!.addEventListener('click', event => {
                    instance.hide();
                });
                // Hide tippy on click escape
                document.addEventListener('keydown', function(event) {
                    if (event.key === 'Escape') {
                        instance.hide();
                    }
                });          
            }}
        >
            <div className="hamburger">
                <span><FontAwesomeIcon icon={faBars} className="icon"/></span>
            </div>
        </Tippy>
        </div>
    )
}

/**
 * Content of the the menu. Hidden on load
 */
function MenuContent() {
    const { userPromise } = useLoaderData() as {userPromise: UserType};

    return (
        <div className="pure-menu navmenu">
            <ul className="pure-menu-list">
                <Suspense>
                    <Await resolve={userPromise}>
                        {(user) => <MenuItems user={user} />}
                    </Await>
                </Suspense>
            </ul>
            <div className="menu-links">
                <Link to={URLS('about')}>About</Link> ·&nbsp;
                <Link to={helpCenterUrl('terms')} target="_blank">Terms</Link> ·&nbsp;
                <Link to={helpCenterUrl('privacy')} target="_blank">Privacy</Link> ·&nbsp;
                <button onClick={logout}>Log out</button>
            </div>
        </div>        
    )
}

/**
 * Items in the hamburger menu
 */
function MenuItems({ user }: { user: UserType }) {

    return (
        <>
            <li>
                <Link to={URLS('account')} className="menu-link account">
                    <div className="pure-g">
                        <div className="pure-u-3-24">
                            <span><FontAwesomeIcon icon={faUser} className="icon-user" /></span>
                        </div>
                        <div className="pure-u-20-24 link-content">
                            <span className="link">Account</span>
                            <span className="email">{user.email}</span>
                        </div>
                    </div>
                </Link>
            </li>
            <li>
                <Link to={URLS('postBookmarks')} className="menu-link">
                    <ItemWithIcon icon={faBookmark} text="Saved articles" iconClassName="icon-bookmark" />
                </Link>
            </li>
            
            {/* Publisher tools */}
            {user.is_publisher && <PublisherTools />}

            {/* Settings and support */}
            <hr />        
            <li><Link to={URLS('settings')} className="menu-link submenu">Settings</Link></li>
            <li><Link to={helpCenterUrl('home')} className="menu-link submenu" target="_blank">Support</Link></li>
            <hr />        
        </>        
    )
}

/**
 * Utilties for publishers
 */
function PublisherTools() {

    return (
        <>
            <hr />
            <li>
                <Link to={URLS('postNew')} className="menu-link submenu publisher" target="_blank">
                    <ItemWithIcon icon={faPenToSquare} text="New article" />
                </Link>
            </li>
            <li>
                <Link to={URLS('postDrafts')} className="menu-link submenu publisher">
                    <ItemWithIcon icon={faFile} text="Drafts" iconClassName="icon-file" />
                </Link>
            </li>
            <li>
                <Link to={URLS('homePreview')} className="menu-link submenu publisher" target="_blank">
                    <ItemWithIcon icon={faEye} text="Preview unpublished" iconClassName="icon-eye" />
                </Link>
            </li>
        </>
    )
}


function ItemWithIcon({
    text,
    icon,
    iconClassName=''
}: {
    text: string,
    icon: IconProp,
    iconClassName?: string
}) {
    return (
        <div className="pure-g">
            <div className="pure-u-3-24">
                <span><FontAwesomeIcon icon={icon} className={`icon ${iconClassName}`} /></span>
            </div>
            <div className="pure-u-20-24 single-link link-content">
                {text}
            </div>
        </div>        
    )
}