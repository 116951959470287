import { MutableRefObject, useEffect, useRef } from 'react';
import {
    useSearchParams,
    useSubmit,
    redirect,
    Form,
} from 'react-router-dom';
import URLS, { getHomeUrlWarning } from 'urls';
import API from 'utils/api';
import { PostCache } from 'storage/postcache';
import AppStorage from 'utils/appstorage';
import 'css/subscription.scss';


/**
 * Render the placeholder UI for the redirect page
 */
export default function LoginRedirect() {
    
    // Get query params in the url
    let [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const otp = searchParams.get('otp');

    // Delete all cached posts on login so that you're not viewing cached previews
    new PostCache().deleteAll({ staleOnly: false })

    // Delete last_visit local timestamp to force an identify call
    const appStorage = new AppStorage();
    appStorage.delete(appStorage.keys.last_visit);
    
    // Submit the form on load
    // How this works:
    // - formRef references the form dom object bc of its ref attribute
    // - useSubmit() is the react router dom that allows us to call submit in code 
    // - useEffect() calls this after the render
    const formRef = useRef(null) as MutableRefObject<null>;
    const submit = useSubmit();
    useEffect(() => {
        submit(formRef.current);
    }, [submit]);

    return (
        <div className="login-loader">
            <div className="lds-ripple"><div></div><div></div></div>

            <Form action={URLS('loginRedirect')} method="post" ref={formRef}>
                <input type="hidden" id="email" name="email" value={email ? email : undefined} />
                <input type="hidden" id="otp" name="otp" value={otp ? otp : undefined} />
            </Form>

        </div>
    )
}

/**
 * Validate and submit the form to try to log in the user
 */
export async function loginRedirectForm({ request }: { request: Request }) {

    // Get data from the hidden form
    const formData = await request.formData();
    const email: FormDataEntryValue = formData.get('email')!;
    const otp: FormDataEntryValue = formData.get('otp')!;

    // Validate data
    const warningUrl = getHomeUrlWarning('auth');
    if (typeof email !== 'string' || !email.includes('@') || typeof otp !== 'string') {
        return redirect(warningUrl);
    }

    // Login with email and otp
    const user = await new API().login(email!.toString(), otp!.toString())
    if (!user.email) {
        return redirect(warningUrl);
    }

    // Successful login and token saved. Go home.
    return redirect(URLS('home'));
}