import { Suspense, useEffect } from 'react';
import { Await, Link, useLoaderData, useOutletContext } from 'react-router-dom';
import URLS from 'urls';
import AppStorage from 'utils/appstorage';
import { useCreateUserRedirect } from 'utils/hooks';
import PricingAnnualMonthly from 'pages/subscription/PricingAnnualMonthly';
import PricingCouponMonthly from 'pages/subscription/PricingCouponMonthly';
import API from 'utils/api';
import 'css/subscription.scss';


/**
 * Page to select pricing when enabling subscription
 */
export default function Pricing() {

    const { pricingPromise } = useLoaderData() as { pricingPromise: DictType }
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    // Redirect to create user if user is not logged in
    useCreateUserRedirect(userPromise);

    useEffect(() => {
        // Save pricing key in local storage so that it's sticky
        if(pricingPromise) {
            (pricingPromise as any).then((pricing: DictType) => {
                const appStorage = new AppStorage();
                appStorage.set(appStorage.keys.subscription_pricing_key, pricing.key);

            });
        }
    }, [pricingPromise]);

    // Track page view
    new API().trackPage('Pricing');

    return (
        <div className="desktop-utilities-content pricing">
            <div className="header">
                <h2 className="header">Your plan</h2>
            </div>

            <div className="body">
                {/* Note: fallback assumes we're only showing the coupon flow.
                If we show a different flow, update this */}
                <Suspense fallback={<PricingCouponMonthly />}>
                    <Await resolve={pricingPromise}>
                        {(pricing) => 
                            pricing.scheme.has_coupon
                            ? <PricingCouponMonthly pricing={pricing}/>
                            : <PricingAnnualMonthly pricing={pricing}/>
                        }
                    </Await>
                </Suspense>
                <Link to={URLS('subscribe')} className="btn-primary">Continue</Link>
            </div>

        </div>
    )
}


