import { Suspense } from 'react';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { DateFormat, MultilinePlaceholder } from 'components/common';
import { getSubscribeUrl } from 'urls';
import { showPaywall } from 'utils/permissions';
import { capitalizeFirstLetter } from 'utils/uihelpers';
import 'css/settings.scss';


export default function PaymentSettings({ user }: { user: UserType }) {
    const { portalPromise, subscriptionPromise } = useLoaderData() as {
        portalPromise: StringDictType,
        subscriptionPromise: StringDictType,
    };

    return (
        showPaywall(user) ?

        // Not currently subscribed
        <Link to={getSubscribeUrl(user)} className="btn-primary">Subscribe</Link>
        :
        // Has current subscription
        <Suspense fallback={<MultilinePlaceholder numLines={3} />}>
            <Await resolve={subscriptionPromise}>
                {(subscription) =>
                <div className="payment-settings">
                    <div className="pure-g">
                        <div className="pure-u-12-24">Plan</div>
                        <div className="pure-u-12-24 right">
                            {subscription.price_dollar} / {subscription.is_annual ? 'year' : 'month'}
                        </div>
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-12-24">Next billing cycle</div>
                        <div className="pure-u-12-24 right">
                            <DateFormat timestamp={subscription.next_payment_timestamp} format="MMMM d, yyyy" />
                        </div>
                    </div>            
                    {
                    subscription.payment_method_brand ?
                    <div className="pure-g">
                        <div className="pure-u-12-24">Payment method</div>
                        <div className="pure-u-12-24 right">
                            {capitalizeFirstLetter(subscription.payment_method_brand)}
                            {subscription.payment_method_last4 &&
                            ' ···· ' + subscription.payment_method_last4}

                            <span className="expiry">
                                {subscription.payment_method_expiry ?
                                <>Expires <DateFormat timestamp={subscription.payment_method_expiry} format="MMM yyyy" /></> : <>&nbsp;</>
                                }
                            </span>
                        </div>
                    </div>
                    // Empty state (should never happen bc we always save payment info)
                    :
                    <div className="pure-g">
                        <div className="pure-u-12-24">Payment method</div>
                        <div className="pure-u-12-24 right">
                            -
                            <span className="expiry">&nbsp;</span>
                        </div>
                    </div>
                    }


                    {/* Link to Stripe customer portal */}
                    <Suspense>
                        <Await resolve={portalPromise}>
                            {(data) => 
                                <div className="links">
                                <Link to={data.url}>View past invoices</Link>
                                <Link to={data.url} className="muted">Update</Link>
                                </div>
                            }
                        </Await>
                    </Suspense>
                </div>
                }
            </Await>
        </Suspense>
    )

}
