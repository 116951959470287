import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getPostUrl } from 'urls';
import { PostCache } from 'storage/postcache';

/**
 * Link to a Post with custom logic to cache the post
 * when the link is visible.
 */
export default function PostLink({
    post,
    children,
}: {
    post: PostType,
    children: React.ReactNode,
}) {

    const ref = useRef<HTMLAnchorElement | null>(null);

    // Observe when the post is visible and cache it if it's not already cached
    useEffect(() => {
        postLinkObserver.observe(ref.current!);
    }, []);    

    return (
        <Link to={getPostUrl(post)} ref={ref} id={post.slug}>
            {children}     
        </Link>
    )
}

// Define the callback function to be called when the element is visible
const onVisibleCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {

  entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        // Fetch and cache the post without forcing
        new PostCache().set({ slug: entry.target.id });  
        // Stop observing the element once it becomes visible (if needed)
        // observer.unobserve(entry.target);
      }
    });
};
  
// Create a new Intersection Observer instance
const postLinkObserver = new IntersectionObserver(onVisibleCallback);
  
