import { Suspense } from 'react';
import { Await, useLoaderData, useOutletContext } from 'react-router-dom';
import PostPreview from 'pages/postlists/PostPreview';
import { useLoginRedirect } from 'utils/hooks';
import 'css/postlists.scss';

/**
 * Render the bookmarks page
 */
export default function Bookmarks() {
    const { userPromise } = useOutletContext() as { userPromise: UserType };
    const { bookmarksPromise } = useLoaderData() as { bookmarksPromise: BookmarkType }

    useLoginRedirect(userPromise);

    return (
        <div className="article-list">
            <h2 className="header">Saved articles</h2>
            <Suspense fallback={<Placeholder />}>
                <Await resolve={bookmarksPromise}>
                    {(bookmarks) => (
                        bookmarks.length > 0 ?
                        bookmarks.map((bookmark: DictType) => (
                            <PostPreview post={bookmark.post} key={bookmark.id}/>
                        ))
                        : <p className="empty">No saved articles yet. Tap on the bookmark icon on any article to save it.</p>
                    )}
                </Await>
            </Suspense>
        </div>
    )
}

export function Placeholder() {
    return (
        <div className="placeholder-content-shimmer">
            <br /><br />
            <div className="shimmerBG content-medium-big"></div>
            <div className="shimmerBG content-medium-big"></div>
            <div className="shimmerBG content-medium-big"></div>
        </div>        
    )
}
