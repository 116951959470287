import Settings from 'utils/settings';

// URL builder for the entire app
export default function URLS (
    name: string,
    to_replace?: string | Array<string>,
    arg?: string | Array<string>,
): string {

    const urlNames: StringDictType = {
        // Subscription
        'createUser': '/create',
        'pricing': '/pricing',
        'subscribe': '/subscribe',
        'subscribeSuccess': '/subscribe/success',
        // User
        'loginRedirect': '/login/redirect',
        'login': '/login',
        'loginConfirm': '/login/link',
        'loginConfirmExistingUser': '/login/link/existing',
        'unsubscribe': '/unsubscribe/:emailType/:useridHash',
        'verifyEmail': '/verify/:useridHash',
        // Settings
        'account': '/account',
        'settings': '/settings',
        'subscriptionSettings': '/settings/subscription',
        'subscriptionSettingsCancel': '/settings/subscription/cancel',
        'accountCancel': '/settings/account/cancel',
        // Home
        'home': '',
        'homePreview': '/preview',   // requires auth
        'homeCategory': '/home/:category',
        'homeCategoryPreview': '/home/preview/:category',
        // Current events
        'currentEvents': '/home/events',
        'currentEventsPreview': '/home/events/preview',
        // Post content
        'post': '/post/:slug',
        'postBookmarks': '/posts/saved',
        // Editing posts
        'postVersion': '/post/:slug/:version',
        'postPreview': '/post/:slug/preview',  // requires auth
        'postEdit': '/post/:slug/edit',
        'postNew': '/post/new',
        'postDrafts': '/posts/drafts',
        // Other
        '404': '/404',
        'about': Settings.ABOUT_URL,
    }

    // Verify that we recognize the url
    if(urlNames[name] === undefined) {
        throw Error('URL not recognized: ' + name);
    }

    // Replace the url args
    let path = urlNames[name];
    if (typeof to_replace === 'string' && typeof arg === 'string') {
        path = path.replace(':' + to_replace, arg);
    // Allow for replacing multiple args
    } else if (Array.isArray(to_replace)  && Array.isArray(arg)) {
        for(let i=0; i<to_replace.length; i++) {
            const key = to_replace[i];
            const val = arg[i];
            path = path.replace(':' + key, val);
        }
    }

    // Trailing slash
    path += '/';

    return path
}

/**
 * Return a regular url or preview url based on whether the post is published
 */
export function getPostUrl(post: PostType | Partial<PostType>): string {
    return (
        post.is_published
        ? URLS('post', 'slug', post.slug)
        : URLS('postPreview', 'slug', post.slug)
    )
}

export function getSubscribeUrl(user: UserType): string {
    // User is already subscribed
    // Note: this shouldn't happen bc we'll only use this url on paywalls
    // where the user is not paying. But putting it in here just in case
    if (user.is_paying_subscriber) {
        return URLS('home');
    // User logged in, go to pricing
    } else if (user.id) {
        return URLS('pricing');
    // New user
    } else {
        return URLS('createUser')
    }
}

export function getHomeUrlWarning(warning: string): string {
    return URLS('home') + '?warning=' + warning; 
}