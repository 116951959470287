import 'css/subscription.scss';

export default function LoginConfirm() {
    return (
        <div className="desktop-utilities-content login-create-form">
            <div className="body">

                <div className="error">
                    An account already exists for this email address. Please check your email to log in.
                </div>
                <h2 className="header">Account exists</h2>    
                <p>An account already exists for this email address. A unique link has been sent to your email. Please check your email to log in.</p>

            </div>
        </div>
    )
}
