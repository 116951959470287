import {
    useLoaderData,
    Await,
    useSearchParams,
    useOutletContext,
} from 'react-router-dom';
import { Suspense } from 'react';
import { ellipsify } from 'utils/uihelpers';
import URLS from 'urls';
import HomeWarnings from 'pages/home/HomeWarnings';
// import CurrentEventsSummary from 'pages/home/CurrentEventsSummary';
import NoPage from 'pages/base/NoPage';
import PostLink from 'components/PostLink';
import { PostCache } from 'storage/postcache';
import 'css/home.scss';


export default function Home() {

    type PostResponseType = {
        posts: Array<PostType>,
        popular_posts: Array<PostType>,
    }    
    // const { postsPromise, currentEventsPromise } = useLoaderData() as {
    const { postsPromise } = useLoaderData() as {
        postsPromise: PostResponseType,
        // currentEventsPromise: Array<PostType> 
    };
    const { userPromise } = useOutletContext() as { userPromise: UserType };

    const [searchParams] = useSearchParams();
    const warning = searchParams.get('warning');

    // Delete all stale cached posts to keep the cache clean
    new PostCache().deleteAll({ staleOnly: true })

    return (
        <div className="pure-g home">

            <Suspense>
                <Await resolve={userPromise}>
                    {(user) => <HomeWarnings warning={warning} user={user} /> }
                </Await>
            </Suspense>

            <Suspense fallback={<Placeholder />}>
            
                <Await resolve={postsPromise}>
                    {(postDict) => (
                    // Verify that user received posts
                    postDict.posts ?
                    <>
                    {/* Primary post */}
                    <FirstPost post={postDict.posts ? postDict.posts[0] : null} />

                    {/* Current events section */}
                    {/* <Suspense fallback="">
                        <Await resolve={currentEventsPromise}>
                            {(event_posts) => 
                                event_posts.length > 3 ? <CurrentEventsSummary posts={event_posts}/> : null
                            }
                        </Await>
                    </Suspense> */}

                    {/* 3 highlighed posts */}
                    <HighlightedPostList posts={postDict.posts} />

                    {/* Secondary posts */}
                    <SecondaryPostList posts={postDict.posts} />

                    {/* Side bar */}
                    {
                        postDict.posts.filter((post: PostType) => post.section === 'secondary').length > 1 &&
                        <ExplorePostList posts={postDict.popular_posts} />
                    }

                    </>
                    // Invalid token if accessed via auth, no posts returend, 404 
                    : <NoPage />
                    )}
                </Await>

            </Suspense>                

        </div>
    )
}  


/*****************************************************************************
 * Components
 *****************************************************************************/

/**
 * Primary post at the top of the page
 */
function FirstPost({ post }: { post: PostType }) {

    if(!post) {
        return <p className="no-posts">No posts to display.</p>
    }

    // Longer preview for one line title
    const previewLength = post.title!.length > 35 ? 310 : 360;

    return (
        <PostLink post={post}>
            <div className="pure-g post-snippet primary-post">

                {post.image &&
                <div className="pure-u-1 pure-u-md-10-24">
                    <img src={post.image} alt=""/>
                </div>}

                <div className={`pure-u-1 ${post.image && "pure-u-md-12-24 post-content-with-image"}`}>
                    <h5>{showCategory() ? post.category?.name : post.topic?.name}</h5>
                    <h2>{post.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: ellipsify(post.content!, previewLength) }} />
                    <ByLine post={post} />
                </div>

            </div>    
        </PostLink>
    );
};


/**
 * 3 highlighted posts below the top section. Wrapper to only show
 * posts if backend returned posts
 */
function HighlightedPostList({ posts }: { posts: Array<PostType> }) {

    const highlightedPosts = posts.filter((post: PostType) => post.section === 'highlighted');
    if (highlightedPosts.length === 0) { 
        return <></>
    }

    return (
        <div className="pure-g highlighted-posts">
            {highlightedPosts.map((post: PostType) => (
                <HighlightedPost post={post} key={post.id} />
            ))}
        </div>
    )
}

function HighlightedPost({ post }: { post: PostType }) {

    // Longer preview for one line title
    const previewLength = post.title!.length > 35 ? 75 : 110;

    return (
        <>
            <div className="post-snippet pure-u-1 pure-u-md-8-24 highlighted-post">
                <PostLink post={post}>
                    {/* Note: can always assume there is an image bc the backend will
                    only highlight a post with an image */}
                    <img className="large" src={post.image!} alt=""/>
                    <h6>{showCategory() ? post.category?.name : post.topic?.name}</h6>
                    <h4>{post.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: ellipsify(post.content!, previewLength) }} />
                    <ByLine post={post} />
                </PostLink>
            </div>
        </>        
    )

}


/**
 * All remaining posts in the Recent section
 */
function SecondaryPostList({ posts }: { posts: Array<PostType> }) {

    const secondaryPosts = posts.filter((post: PostType) => post.section === 'secondary');
    if (secondaryPosts.length === 0) { 
        return <></>
    }

    return (
        <div className="pure-u-5-5 pure-u-md-16-24 secondary-post-wrapper">
            <div className="label">Recent</div>
            {secondaryPosts.map((post: PostType) => (
                <SecondaryPost post={post} key={post.id} />
            ))}
        </div>
    )
}


function SecondaryPost({ post }: { post: PostType }) {

    // Content preview based on screen size
    const contentPreviewLength: number = window.innerWidth > 768 ? 140 : 60;
    const contentPreview = ellipsify(post.content!, contentPreviewLength)

    return (
        <>
            <PostLink post={post}>
                <div className="pure-g post-snippet secondary-post">

                    <div className={post.image ? "pure-u-15-24 pure-u-md-16-24" : "pure-u-1"}>
                        <h6>{showCategory() ? post.category?.name : post.topic?.name}</h6>
                        <h4>{post.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: contentPreview }} />
                        <ByLine post={post} />
                    </div>
                    {
                        post.image &&
                        <>
                            <div className="pure-u-1-24"></div>
                            <div className="pure-u-8-24 pure-u-md-7-24">
                                <img src={post.image} alt="" />
                            </div>
                        </>
                    }
                </div>
            </PostLink>
        </>        
    )
}


/**
 * Render an explore post in the explore section (image and title)
 */
function ExplorePostList({ posts }: { posts: Array<PostType> }) {

    if (posts.length < 1) { 
        return <></>
    }

    return (
        <>
        <div className="pure-u-md-1-24 side-bar-spacer"></div>
        <div className="pure-u-5-5 pure-u-md-7-24 side-bar">
            <div className="label">Most read</div>
            {posts.map((post: any) => (
                <ExplorePost post={post} key={post.id}/>
            ))}
        </div>        
        </>
    )
}


function ExplorePost({ post }: { post: PostType }) {
    return (
        <PostLink post={post}>
            <div className="pure-g">
                <div className="pure-u-7-24">
                    <img src={post.image || undefined} alt="" />
                </div>
                <div className="pure-u-17-24">
                    <h6>{post.title}</h6>
                </div>
            </div>
        </PostLink>        
    )
}

/**
 * By line to display on a post
 */
function ByLine({ post }: { post: PostType }) {
    return (
        <div className="byline">
            {/* {
                (post.author && post.author.first_name) &&
                <span>{post.author.first_name} {post.author.last_name} · </span>
            }
            <span>
                {post.published_timestamp
                ? <><DateFormat timestamp={post.published_timestamp} format="MMM d"/> · </>
                : <>Draft · </>}
            </span> */}
            <span>{post.read_time_minutes} min read</span>
        </div>
    )
}

/**
 * Render a shimmer placeholder for the home screen while we fetch data async
 */
function Placeholder() {
    return (
        <div className="placeholder-content-shimmer">
            <div className="pure-g post-snippet primary-post">
                <div className="pure-u-1 pure-u-md-10-24">
                    <div className="shimmerBG content-big"></div>
                </div>
                <div className="pure-u-1 pure-u-md-1-24"></div>
                <div className="pure-u-1 pure-u-md-13-24">
                    <div className="shimmerBG content-medium"></div>
                    <div className="shimmerBG content-small"></div>
                    <div className="shimmerBG content-small"></div>
                    <div className="shimmerBG content-small"></div>
                </div>
            </div>
            <div className="shimmerBG content-medium"></div>
            <div className="shimmerBG content-medium"></div>
            <div className="shimmerBG content-medium"></div>
            <div className="shimmerBG content-medium"></div>
        </div>        
    )
}

// Return true if should show category for an article; otherwise show topic (e.g. on category pages)
function showCategory(): boolean {
    return (
        window.location.pathname === URLS('home')
        || window.location.pathname === URLS('homePreview')    
    )
};
