import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';


export default function PopOver(props: { content: JSX.Element, children: JSX.Element }){

    return (
        <Tippy
            trigger="click"
            placement="bottom"
            theme="light"
            offset={[0, 15]}
            content={props.content}
            interactive={true}
            onShown={(instance) => {
                // Hide tippy on click inside
                document.querySelector('[data-tippy-root]')!.addEventListener('click', event => {
                    instance.hide();
                });
            }}            
        >
           {props.children}
        </Tippy>
    )
}
