import Settings from 'utils/settings'
import AppStorage from 'utils/appstorage'
import { getUtmParams } from 'utils/advertising';
import { PostCache } from 'storage/postcache';
import { CategoriesCache } from 'storage/categoriescache';
import { isDarkMode } from 'utils/uihelpers';


/**
 * Global API client for the the site
 */
export default class API {

    // Base app url
    static BASE_URL: string = Settings.API_BASE_URL + '/api/';

    // Auth token
    authToken: string | null = null;

    // Should auth
    shouldAuth: boolean = false;

    // Endpoints

    static endpoints: StringDictType = {
        user: 'user',
        userStats: 'user/stats',
        subscription: 'subscription',
        subscriptionPortal: 'subscription/portal',
        userVerifyEmail: 'user/verifyemail',
        auth: 'auth',
        notifications: 'user/notifications',
        unsubscribe: 'user/unsubscribe',
        posts: 'posts',
        postsPreview: 'posts/preview',
        post: 'post',
        postVersions: 'post/versions',
        currentEvents: 'posts/events',
        currentEventsPreview: 'posts/events/preview',
        postPreview: 'post/preview',
        postPermissions: 'post/permissions',
        postBookmark: 'post/bookmark',
        postBookmarksAll: 'bookmarks',
        postDrafts: 'posts/drafts',
        categories: 'categories',
        track: 'track',
    }

    // User management

    async getUser() {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.user,
            requiresAuth: true,
        })
    }

    async updateUser(data: DictType) {
        return this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.user,
            data: data,
            requiresAuth: true,
        })
    }

    async getOrCreateUser({
        email,
        first_name,
        last_name,
        industry
    } : {
        email: string,
        first_name?: string,
        last_name?: string,
        industry?: string
    }) {
        // Get UTM params from local storage to pass on user creation
        const utmParams: DictType = getUtmParams();
        // combine dicts (... is the spread operator, like **dict in python)
        const data: StringDictType = {
            ...{email: email},
            ...utmParams
        };
        if (first_name) { data.first_name = first_name; };
        if (last_name) { data.last_name = last_name; };
        if (industry) { data.industry = industry; };

        const user = await this._fetch({
            method: 'POST',
            endpoint: API.endpoints.user,
            data: data
        })
        // If this is the user's first login, the backend will return an
        // auth token so we can log them in automatically.
        if(user?.token) {
            const appStorage = new AppStorage();
            appStorage.set(appStorage.keys.token, user['token']);
        }
        return user
    }

    async sendOTPToken(email: string) {
        // Send an existing user the Login email with an otp token.
        // Will always return {}
        return await this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.auth,
            data: {email: email}
        })        
    }

    async sendEmailVerificationLink() {
        // Send a logged in user the verify email with a link to verify email
        // Will always return no content
        return await this._fetch({
            method: 'POST',
            endpoint: API.endpoints.userVerifyEmail
        })
    }

    async login(email: string, otp: string) {
        const data: StringDictType = {
            email: email,
            otp: otp,
        };
        const user = await this._fetch({
            method: 'POST',
            endpoint: API.endpoints.auth,
            data: data
        })

        if(user?.token) {
            // Store the token for future use
            const appStorage = new AppStorage()
            appStorage.set(appStorage.keys.token, user['token']);
        }
        return user
    }

    async logout() {
        // Logout on the server, then delete local token
        const response = this._fetch({
            method: 'DELETE',
            endpoint: API.endpoints.auth,
            requiresAuth: true,
        })
        this._clearAuthToken();
        return response
    }

    async deactivateUser() {
        // Deactivate on the server, then delete local token
        const response = this._fetch({
            method: 'DELETE',
            endpoint: API.endpoints.user,
            requiresAuth: true,
        })
        this._clearAuthToken();
        return response
    }

    async getNotificationSettings() {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.notifications,
            requiresAuth: true,
        })
    }

    async updateNotifications(email_type: string, value: boolean) {
        return this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.notifications,
            args: [email_type],
            data: {value: value},
            requiresAuth: true,
        }) 
    }

    async unsubscribeNotifications(email_type: string, userid_hash: string) {
        return await this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.unsubscribe,
            args: [email_type],
            data: {userid_hash: userid_hash}
        }) 
    }

    async verifyEmail(userid_hash: string) {
        const data: {[key: string]: string | boolean} = {
            is_email_verified: true,
            userid_hash: userid_hash
        }
        return await this._fetch({
            method: 'PATCH',
            endpoint: API.endpoints.user,
            data: data
        }) 
    }

    async getUserStats() {
        return await this._fetch({
            method: 'GET',
            endpoint: API.endpoints.userStats,
            requiresAuth: true,
        })         
    }

    // Subscription management

    async getSubscriptionPricing(pricingKey: string | null) {
        // If the user has already seen a pricing scheme, keep it sticky
        const args = pricingKey === null ? [] : [pricingKey];
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.subscription,
            args: args,
        })
    }

    async getSubscription() {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.subscription,
            requiresAuth: true,
        })
    }

    async createSubscription(data: DictType) {
        return this._fetch({
            method: 'POST',
            endpoint: API.endpoints.subscription,
            data: data,
            requiresAuth: true,
        })
    }

    async cancelSubscription() {
        return this._fetch({
            method: 'DELETE',
            endpoint: API.endpoints.subscription,
            requiresAuth: true,
        })
    }

    async confirmPayment(paymentIntentId: string) {
        const data = {'payment_intent_id': paymentIntentId};
        return this._fetch({
            method: 'PATCH',
            endpoint: API.endpoints.subscription,
            data: data,
            requiresAuth: true,
        })
    }

    async getSubscriptionPortal(page: string) {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.subscriptionPortal,
            args: [page],
            requiresAuth: true,
        })
    }

    // Content

    async getPosts({ category, isPreview } : { category: string, isPreview?: boolean }) {
        const args = Boolean(category) ? [category] : [];
        const endpoint = isPreview ? API.endpoints.postsPreview : API.endpoints.posts;
        return this._fetch({
            method: 'GET',
            endpoint: endpoint,
            args: args
        })             
    }

    async getCategories({
        topicType,
        useCache = true
    } : {
        topicType?: string,
        useCache?: boolean,
    }) {

        // Get from cache if available and not stale
        const cached = new CategoriesCache().get();
        if (cached && useCache) {
            return cached;
        }

        const args = topicType !== undefined ? [topicType] : ['all'];
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.categories,
            args: args
        })             
    }

    async getCurrentEvents(isPreview?: boolean) {
        const endpoint = isPreview ? API.endpoints.currentEventsPreview : API.endpoints.currentEvents;
        return this._fetch({
            method: 'GET',
            endpoint: endpoint,
        })             
    }

    async getDraftPosts() {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.postDrafts,
            requiresAuth: true,
        })             
    }

    // Post

    async getPost({ slug, version, useCache }: {
        slug: string,
        version?: string,
        useCache?: boolean
    }) {

        const cachedPost = new PostCache().get(slug);
        if (!version && useCache && cachedPost) {
            return cachedPost;
        }

        const args = version === undefined ? [slug] : [slug, version];
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.post,
            args: args
        })             
    }

    async getPostPreview({ slug, version, useCache }: {
        slug: string,
        version?: string,
        useCache?: boolean
    }) {

        const cachedPost = new PostCache().get(slug);
        if (!version && useCache && cachedPost) {
            return cachedPost;
        }

        const args = version === undefined ? [slug] : [slug, version];
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.postPreview,
            args: args
        })             
    }

    async updatePost(post: Partial<PostType>) {
        // Delete post cache to force recache
        new PostCache().delete(post.slug!);
        return this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.post,
            args: [post.id!],
            data: post,
            requiresAuth: true,
        })             
    }

    async createPost() {
        return this._fetch({
            method: 'POST',
            endpoint: API.endpoints.post,
            requiresAuth: true,
        })             
    }

    async getPostVersions(slug: string) {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.postVersions,
            args: [slug],
            requiresAuth: true,
        })         
    }

    async restorePostFromVersion(slug: string, versionId: string) {
        // Delete post cache to force recache
        new PostCache().delete(slug);
        return this._fetch({
            method: 'PATCH',
            endpoint: API.endpoints.post,
            args: [slug, versionId],
            requiresAuth: true,
        })         
    }

    async updatePostImage(post: Partial<PostType>, formData: FormData) {
        // Handle manually to upload the image with form data
        const tokenSet = this._setAuthToken();
        if (!tokenSet) {
            return {}
        }
        const url: string = this._buildUrl(API.endpoints.post, [post.slug!]);
        const response = await fetch(url, {
            method: 'PUT',
            headers: this._headers(false),
            body: formData,
        });
        return response.json();        
    }

    // Bookmarks

    async getPostBookmark(slug: string) {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.postBookmark,
            args: [slug],
            requiresAuth: true,
        })         
    }

    async bookmarkPost(slug: string, shouldBookmark: boolean) {
        return this._fetch({
            method: 'PUT',
            endpoint: API.endpoints.postBookmark,
            args: [slug],
            data: {'should_bookmark': shouldBookmark},
            requiresAuth: true,
        })         
    }

    async getAllPostBookmarks() {
        return this._fetch({
            method: 'GET',
            endpoint: API.endpoints.postBookmarksAll,
            requiresAuth: true,
        })         
    }

    // Analytics

    async track(data: DictType) {

        // default items to always pass to the api
        data['user_agent'] = navigator.userAgent;
        data['is_dark_mode'] = isDarkMode();

        return this._fetch({
            method: 'POST',
            endpoint: API.endpoints.track,
            data: data
        })         
    }

    async trackPage(page: string) {
        const data: DictType = {
            user_agent: navigator.userAgent,
            is_dark_mode: isDarkMode(),
            event: 'v',
            page: page,
        }

        const appStorage = new AppStorage();
        const pricingKey = appStorage.get(appStorage.keys.subscription_pricing_key);
        if (pricingKey) {
            data['pricing_key'] = pricingKey
        }

        return this._fetch({
            method: 'POST',
            endpoint: API.endpoints.track,
            data: data
        })         
    }

    async identify(url: string) {
        // Wait 15 minute between identify calls to limit server load
        const appStorage = new AppStorage();
        const lastVisit = appStorage.get(appStorage.keys.last_visit);
        const min15 = 15 * 60 * 1000;
        const now = Date.now();
        if (now - lastVisit < min15) {
            return
        }
        appStorage.set(appStorage.keys.last_visit, now);

        // Track
        const data = {
            event: 'i',
            url: url,
        }
        return this.track(data)
    }

    // Helpers

    async _fetch({
        method,
        endpoint,
        args,
        data,
        requiresAuth,
    }: {
        method: string,
        endpoint: string,
        args?: Array<string>,
        data?: DictType,
        requiresAuth?: boolean,
    }) {
        const tokenSet = this._setAuthToken();

        // Do not hit the API for an endpoint that requires auth if there is no token
        if (requiresAuth && !tokenSet) {
            console.warn(`No token available for an endpoint that requires auth: ${endpoint}`);
            return {}
        }

        const url = this._buildUrl(endpoint, args);
        const fetchData: {[key: string]: any} = {
            method: method,
            headers: this._headers()
        }
        if (data) {
            fetchData.body = JSON.stringify(data);
        }
        const response = await fetch(url, fetchData)
        // If received a 401 and we had set a token, the token has expired.
        // This should virtually never happen because our token never expires.
        // But, to guarge against it, clear the token for future endpoints
        // and reload get
        if (response.status === 401 && tokenSet) {
            this._clearAuthToken()
            // On get requests, reload the page once we've cleared the invalid token,
            // which will load the page unauthed.
            if (method === 'GET') {
                window.location.reload();
            }
        }        
        return response.json();
    }

    _headers(includeContentType: boolean = true) {
        let headers: { [key: string]: string } = {};
        if (includeContentType) {
            headers['Content-Type'] = 'application/json';
        }
        if (this.authToken) {
            headers['Authorization'] = 'Token ' + this.authToken;
        }
        return headers
    }

    _setAuthToken(): boolean {
        // Set token and return true if the token was set

        const appStorage = new AppStorage();
        const token = appStorage.get(appStorage.keys.token);

        // Only set token if it exists. If not, treat it as an unauthed endpoint
        if(token) {
            this.authToken = token;
            return true
        } else {
            // Reset in case same client instance called repeatedly
            this.authToken = null;
            return false
        }
    }

    _clearAuthToken() {
        this.authToken = null;
        const appStorage = new AppStorage();
        appStorage.delete(appStorage.keys.token);
    }

    _buildUrl(endpoint: string, args?: Array<string>) {
        let url = `${API.BASE_URL}${endpoint}/`;
        args?.forEach(function(arg) {
            url += `${arg}/`;
        });
        return url
    }    
}


// Return true if has a token saved in local storage
export function hasAuthToken() {
    const appStorage = new AppStorage();
    const token = appStorage.get(appStorage.keys.token);
    return Boolean(token);
}