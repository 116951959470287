import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import Settings from 'utils/settings';
import {
    addFacebookPixel,
    addGoogleAnalytics,
    addLinkedinInsightTag,
    addTwitterPixel
} from 'utils/advertising';


const rootElement = document.getElementById('root');
if (rootElement) {

    if (Settings.isProd()) {
        addGoogleAnalytics();
        addLinkedinInsightTag();
        addFacebookPixel();
        addTwitterPixel();
    }

    const root = ReactDOM.createRoot(rootElement as HTMLElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}