import {
    useLoaderData,
    Await,
    useOutletContext,
} from 'react-router-dom';
import { FormEventHandler, Suspense, useEffect, useRef } from 'react';
import { handleContentEditableBlur } from 'utils/uihelpers';
import { Image, DateFormat } from 'components/common';
import NoPage from 'pages/base/NoPage';
import PostPreview from 'pages/post/PostPreview';
import PostToolbar from 'pages/post/PostToolbar';
import { PostPaywall } from 'pages/home/Paywall';
import PostContent from 'pages/post/PostContent';
import { PostCache } from 'storage/postcache';
import { usePostMetaData } from 'utils/hooks';
import API from 'utils/api';
import { showPaywall } from 'utils/permissions';
import 'css/post.scss';


// Type of Post object
export const PostPostType = {
    full: 'f',
    short: 's',
    static: 't',
}


export default function Post() {
    const { postPreviewPromise, postPromise } = useLoaderData()  as {
        postPreviewPromise: PostType,
        postPromise: PostType,
    };

    const { userPromise } = useOutletContext() as { userPromise: UserType };

    // Set page title to post title
    usePostMetaData(postPreviewPromise)

    // Cache the post
    useEffect(() => {
        new PostCache().set({ postPromise: postPromise });
    }, [postPromise]);

    return (
        <div className="post">

            <Suspense fallback={<PostPreview />}>
                <Await resolve={postPreviewPromise}>
                    {(post) =>
                    // Post found and viewable
                    post.id ?
                    <>
                    
                        <Warnings post={post} />
                        <Category post={post}/>
                        <Title title={post.title} />

                        {/* No toolbar on static pages, e.g. the about page */}
                        {post.post_type !== PostPostType.static &&
                            <PostToolbar post={post} showTools={post.can_edit}/>
                        }

                        <Image className="hero-img" src={post.image} />
                        <ImageCaption caption={post.image_caption} />
                        <Subtitle subtitle={post.subtitle} />

                        {/* Only show byline for long articles */}
                        {post.post_type === PostPostType.full &&
                            <ByLine post={post} />
                        }

                        <PostContent post={post} isPreview={true} />

                        {/* Show paywalls (applicability handled in paywall) */}
                        <Await resolve={userPromise}>
                            {(user) => <PostPaywall user={user} post={post} />}
                        </Await>

                    </>
                    // No post found
                    : <NoPage />
                    }
                </Await>
            </Suspense>

            {/* Full post */}
            <Suspense>
                <Await resolve={postPromise}>
                    {(postFull) => 
                        <>
                        <PostContent post={postFull} />

                        {/* Show share/save at the end of a post */}
                        <Await resolve={userPromise}>
                            {(user) => 
                                (postFull.post_type === PostPostType.full && !showPaywall(user)) &&
                                <>
                                <br />
                                <PostToolbar post={postFull} showTools={false}/>
                                </>
                            }
                        </Await>

                        {/* Post tracking that the entire post was read */}
                        <PostEndTracking post={postFull} />
                        </>
                    }
                </Await>
            </Suspense>

        </div>
    );
};


/*****************************************************************************
 * Components
 *****************************************************************************/

/**
 * Category and topic of a post
 */
function Category({ post }: { post: PostType }) {
    if(post.category === null || post.topic === null) { 
        return null;
    }
    return (
        <div className="topics">
            {/* TODO: disabled until we bring back categories */}
            {/* <Link to={URLS('homeCategory', 'category', post.category.slug)}>
                <h4>{post.category.name}</h4>
            </Link> */}
            <h4>{post.category.name}</h4>
            <h4> | {post.topic.name}</h4>
        </div>
    )
}

/**
 * Subtitle component to show below the image if it exists
 */
export function Title(props: {
    title: string,
    isEditable?: boolean,
    onInput?: Function,
}) {

    const titleRef: React.RefObject<HTMLDivElement> = useRef(null);

    // Focus on the title when page loads
    useEffect(() => {
        titleRef.current!.focus();
    }, [titleRef]);

    // Enter goes to subtitle, same as tab. Keystrokes feel more natural this way.
    function handleKeyPress(event: any) {
        if (event.key === 'Enter') {
            event.preventDefault(); // disable enter
            let nextTabElement: HTMLElement | null = document.querySelector('div.post-subtitle');
            // Go to subtitle if it exists (i.e. long article)
            if(nextTabElement) {
                nextTabElement.focus();
            // Go to content otherwise (i.e. short article)
            } else {
                nextTabElement = document.querySelector('.mce-content-body')!;
                nextTabElement.focus();
            }
        }      
    }

    return (
        <h1
            tabIndex={1}
            ref={titleRef}
            contentEditable={props.isEditable}
            suppressContentEditableWarning={props.isEditable}
            onInput={props.onInput as FormEventHandler}
            placeholder="Untitled"
            onBlur={(e) => handleContentEditableBlur(e.target)}
            onKeyDown={(e) => handleKeyPress(e)}
        >
            {props.title}
        </h1>
    )
};  

/**
 * Image caption component to show below the image if it exists
 */
export function ImageCaption(props: {
    caption: string,
    isEditable?: boolean,
    onInput?: Function,
}) {

    return (
        <div
            tabIndex={4}
            className="post-image-caption"
            contentEditable={props.isEditable}
            suppressContentEditableWarning={props.isEditable}
            onInput={props.onInput as FormEventHandler}
            placeholder="Enter image caption"
            onBlur={(e) => handleContentEditableBlur(e.target)}
        >
            {props.caption}
        </div>
    )
};  

/**
 * Subtitle component to show below the image if it exists
 */
export function Subtitle(props: {
    subtitle: string,
    isEditable?: boolean,
    onInput?: Function,
}) {

    // Enter goes to content, same as tab. Keystrokes feel more natural this way.
    function handleKeyPress(event: any) {
        if (event.key === 'Enter') {
            event.preventDefault();
            const nextTabElement: HTMLElement | null = document.querySelector('.mce-content-body')!;
            nextTabElement.focus();
        }      
    }

    return (
        <div
            tabIndex={2}
            className="post-subtitle"
            contentEditable={props.isEditable}
            suppressContentEditableWarning={props.isEditable}
            onInput={props.onInput as FormEventHandler}
            placeholder="Enter subtitle"
            onBlur={(e) => handleContentEditableBlur(e.target)}
            onKeyDown={(e) => handleKeyPress(e)}
        >
            {props.subtitle}
        </div>
    )
};  

/**
 * ByLine with author photo and avatar to show above content
 */
export function ByLine({ post }: { post: Partial<PostType> }) {
    if( !post.author ) {
        return <></>
    }
    return (
        <div className="author pure-g">
            <div className="pure-u-4-24 pure-u-md-2-24">
                <img src={post.author?.avatar || undefined} alt='Author' />
            </div>
            <div className="pure-u-20-24">
                <span className="name">
                    {post.author?.first_name} {post.author?.last_name}
                </span>
                <span className="date">
                    {
                        post.read_time_minutes
                        ? <>{post.read_time_minutes} min read · </>
                        : null
                    }
                    {
                        post.published_timestamp
                        ? <DateFormat timestamp={post.published_timestamp} format='MMM d, yyyy' />
                        : <>Draft</>
                    }
                    
                </span>
            </div>
        </div>
    ) 
};


/**
 * Component to display at the top of the page if the post is archived
 */
export function Warnings({ post }: { post: Partial<PostType> }) {
    // Prior version
    if(post.version_id) {
        return (
            <div className="info">
                This is a prior version of this post, last saved on <DateFormat timestamp={post.version_timestamp!} format="MMMM d, Y, h:mm:ss bbb" />.
            </div>
        )
    }
    return <></>
}

/**
 * Track that the end of a post has been reached. Send a view event so we can
 * measure % of posts being read fully.
 */
function PostEndTracking({ post }: { post: PostType }) {

    const ref = useRef<HTMLDivElement | null>(null);

    // Only track once
    let hasViewed = false;

    // Define the callback function to be called when the element is visible
    const onPostEndCallback = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
    ) => {

        entries.forEach((entry: IntersectionObserverEntry) => {
            // Track the post view once
            if (!hasViewed && entry.isIntersecting) {
                hasViewed = true;
                const trackingData: StringDictType = {
                    'event': 'co',
                    'page': 'Post',
                    'slug': post.slug,
                }
                new API().track(trackingData);
            }
        });
    };
        
    // Create a new Intersection Observer instance
    const postEndObserver = new IntersectionObserver(onPostEndCallback);
        
    // Observe when the post end is visible
    useEffect(() => {
        postEndObserver.observe(ref.current!);
    }, []);    

    return <div ref={ref} id={post.slug} />
}