import { Link } from 'react-router-dom';
import URLS from 'urls';

export default function Unsubscribe() {
    return (
        <>
            <h3>Settings updated</h3>
            <p>You've been unsubscribed. To manage your notifications settings, visit <Link to={URLS('settings')} className="underline">settings</Link>.</p>
        </>
    );
};
