import { defer, redirect } from 'react-router-dom';
import { CategoriesCache } from 'storage/categoriescache';
import URLS from 'urls';
import API from 'utils/api';
import AppStorage from 'utils/appstorage';


/*****************************************************************************
 * Home/multi post/categories
 *****************************************************************************/

// Home page loader. Load all posts or filter by category, or previewing
export async function postsLoader({ params }: DictType) {
    const api = new API();

    // Preview unpublished articles for publishers
    const isPreview = window.location.pathname.includes('preview');

    // Filter by category if passed
    const postsPromise = api.getPosts({
        category: params.category,
        isPreview: isPreview
    });

    // Only fetch curent on home with no category filter
    // let currentEventsPromise = {};
    // if(!params.category) {
    //     currentEventsPromise = api.getCurrentEvents();
    // }
    // return defer({ postsPromise, currentEventsPromise });

    return defer({ postsPromise });
};

// Drafts page loader. Load all drafts for a publisher.
export function draftsLoader() {
    const draftsPromise = new API().getDraftPosts();
    return defer({ draftsPromise });
}

// Load categories for Home page nav bar
export async function categoriesLoader() {
    const categoriesPromise = new API().getCategories({topicType: 'c'});
    new CategoriesCache().set(categoriesPromise)
    return defer({ categoriesPromise });
};

/*****************************************************************************
 * Single Post
 *****************************************************************************/

// Post page loader
export async function postLoader({ params }: { params: DictType } ) {
    const appStorage = new AppStorage();
    const api = new API();
    
    // Post preview
    const postPreviewPromise = api.getPostPreview({
        slug: params.slug,
        version: params.version,
        useCache: true,
    });
    const postPromise = api.getPost({
        slug: params.slug,
        version: params.version,
        useCache: true,
    });

    // Full post and bookmark only shown to logged in users
    let bookmarkPromise: DictType = {'is_bookmarked': false};
    if (appStorage.get(appStorage.keys.token)) {
        bookmarkPromise = api.getPostBookmark(params.slug);
    }

    // Track the post view
    const trackingData: StringDictType = {
        'event': 'v',
        'page': 'Post',
        'slug': params.slug,
    }
    new API().track(trackingData);    

    return defer({
        postPreviewPromise, postPromise, bookmarkPromise
    });
};


// EditPost page loader
export async function editPostLoader({ params }: { params: DictType } ) {
    const api = new API();
    const postPromise = api.getPost({
        slug: params.slug,
    });
    const categoriesPromise = api.getCategories({useCache: false});
    const bookmarkPromise = api.getPostBookmark(params.slug);
    const versionsPromise = api.getPostVersions(params.slug);
    return defer({
        postPromise, categoriesPromise, bookmarkPromise, versionsPromise
    });
}

// EditPost page loader for new post.
// Create a new post and then redirect to the Edit page of that post
export async function newPostLoader() {
    const post: DictType = await new API().createPost();
    return redirect(URLS('postEdit', 'slug', post.id));
}
